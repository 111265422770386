import { some } from "../common/constants";
import { stringify } from "query-string";
import { mappedFilter } from "../common/utils";
export const development: boolean =
  !process.env.NODE_ENV || process.env.NODE_ENV === "development";

const getBaseUrl = development
  ? "/api/kttv/"
  : process.env.REACT_APP_DOMAIN_API;
const getAuthUrl = development
  ? "/api/auth/"
  : process.env.REACT_APP_DOMAIN_AUTH;

export const API_PATHS = {
  auth: {
    validateToken: `${getBaseUrl}/api/auth/me`,
    login: (params: some) =>
      `${getAuthUrl}/api/generate-token?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    signUp: `${getBaseUrl}/api/auth/signup`,
    changePassword: `${getAuthUrl}/api/change_password`,
    resetToken: `${getAuthUrl}/api/auth/resetToken`,
    edit: (username: string) => `${getBaseUrl}/api/user/edit/${username}`,
  },
  map: {
    index: (params: some) => `${getBaseUrl}/api/map?${stringify(params)}`,
    station: (id: string) => `${getBaseUrl}/api/map/${id}`,
  },
  upload: {
    uploadSingleFile: `${getBaseUrl}/api/file/upload`,
    uploadMultiFile: `${getBaseUrl}/api/file/upload-multiple`,
    getFile: (filename: string) => `${getBaseUrl}/api/file/files/${filename}`,
  },
  dashboard: {
    construction: `${getBaseUrl}/api/dashboard/constructions-total`,
    constructionStatistic: `${getBaseUrl}/api/dashboard/constructions-statistic`,
    stationsDistribution: (params: some) =>
      `${getBaseUrl}/api/dashboard/stations-distribution?${stringify(
        mappedFilter(params)
      )}`,
    constructionsDistribution: (params: some) =>
      `${getBaseUrl}/api/dashboard/constructions-distribution?${stringify(
        mappedFilter(params)
      )}`,

    dataReceive: (params: some) =>
      `${getBaseUrl}/api/dashboard/data-received-ratio?${stringify(
        mappedFilter(params)
      )}`,

    compareData: (params: some) =>
      `${getBaseUrl}/api/dashboard/data-received-ratio-statistic?${stringify(
        params
      )}`,
  },
  monitoring: {
    index: (params: some) =>
      `${getBaseUrl}/api/monitoring?${stringify(mappedFilter(params))}`,

    indicators: (params: some) =>
      `${getBaseUrl}/api/monitoring/indicators?${stringify(
        mappedFilter(params)
      )}`,

    dataLasted: (params: some) =>
      `${getBaseUrl}/api/v1/monitoring/data-lasted?${stringify(
        mappedFilter(params)
      )}`,
    stationTypes: (params: some) =>
      `${getBaseUrl}/api/v1/monitoring/station-types?${stringify(
        mappedFilter(params)
      )}`,
    dataLastedby: (params: some) =>
      `${getBaseUrl}/api/v1/monitoring/data-lasted-by?${stringify(
        mappedFilter(params)
      )}`,
  },
  camera: {
    index: (params?: some) =>
      `${getBaseUrl}/api/camera?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/camera/${id}`,
    create: `${getBaseUrl}/api/camera`,
  },
  monitoringLocation: {
    index: (params?: some) =>
      `${getBaseUrl}/api/monitoring-location?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/monitoring-location/${id}`,
    create: `${getBaseUrl}/api/monitoring-location`,
  },
  cameraOnline: {
    index: `${getBaseUrl}/api/camera-station`,
  },
  qcvn: {
    index: (params?: some) =>
      `${getBaseUrl}/api/qcvn?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/qcvn/${id}`,
    create: `${getBaseUrl}/api/qcvn`,
  },
  sensor: {
    index: (params?: some) =>
      `${getBaseUrl}/api/sensor?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/sensor/${id}`,
    create: `${getBaseUrl}/api/sensor`,
  },
  threshold: {
    index: (params: some) =>
      `${getBaseUrl}/api/threshold?${stringify(mappedFilter(params))}`,
    update: (id: string) => `${getBaseUrl}/api/threshold/${id}`,
    create: `${getBaseUrl}/api/threshold`,
  },
  thresholdIndicator: {
    index: (thresholdId: string) =>
      `${getBaseUrl}/api/threshold/${thresholdId}/indicator-threshold`,
    update: (thresholdId: string, id: string) =>
      `${getBaseUrl}/api/threshold/${thresholdId}/indicator-threshold/${id}`,
    delete: (id: string) =>
      `${getBaseUrl}/api/threshold/indicator-threshold/${id}`,
  },
  dataLogger: {
    index: (params?: some) =>
      `${getBaseUrl}/api/datalogger?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/datalogger/${id}`,
    create: `${getBaseUrl}/api/datalogger`,
  },
  area: {
    index: (params?: some) =>
      `${getBaseUrl}/api/area?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/area/${id}`,
    create: `${getBaseUrl}/api/area`,
  },
  province: {
    index: (params?: some) =>
      `${getBaseUrl}/api/province?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/province/${id}`,
    create: `${getBaseUrl}/api/province`,
  },
  district: {
    index: (params?: some) =>
      `${getBaseUrl}/api/district?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/district/${id}`,
    create: `${getBaseUrl}/api/district`,
  },
  commune: {
    index: (params?: some) =>
      `${getBaseUrl}/api/commune?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/commune/${id}`,
    create: `${getBaseUrl}/api/commune`,
  },
  nreField: {
    index: (params?: some) =>
      `${getBaseUrl}/api/nre-field?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/nre-field/${id}`,
    create: `${getBaseUrl}/api/nre-field`,
  },
  nreType: {
    index: (params?: some) =>
      `${getBaseUrl}/api/nre-type?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/nre-type/${id}`,
    create: `${getBaseUrl}/api/nre-type`,
  },
  indicator: {
    index: (params?: some) =>
      `${getBaseUrl}/api/indicator?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/indicator/${id}`,
    create: `${getBaseUrl}/api/indicator`,
  },
  analytical: {
    index: (params?: some) =>
      `${getBaseUrl}/api/analytical-method?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/analytical-method/${id}`,
    create: `${getBaseUrl}/api/analytical-method`,
  },
  riverBasin: {
    index: (params?: some) =>
      `${getBaseUrl}/api/river-basin?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/river-basin/${id}`,
    create: `${getBaseUrl}/api/river-basin`,
  },
  license: {
    index: (params?: some) =>
      `${getBaseUrl}/api/license?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/license/${id}`,
    create: `${getBaseUrl}/api/license`,
  },
  licenseMetaData: {
    index: (licenseId: string) =>
      `${getBaseUrl}/api/license/${licenseId}/metadata`,
    update: (licenseId: string, id: string) =>
      `${getBaseUrl}/api/license/${licenseId}/metadata/${id}`,
    getById: (id: string) => `${getBaseUrl}/api/license/metadata/${id}`,
  },
  licensingType: {
    index: (params?: some) =>
      `${getBaseUrl}/api/licensing-type?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/licensing-type/${id}`,
    create: `${getBaseUrl}/api/license`,
  },
  licenseType: {
    index: (params?: some) =>
      `${getBaseUrl}/api/license-type?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/license-type/${id}`,
    create: `${getBaseUrl}/api/license-type`,
  },
  licenseTypeMetaData: {
    index: (licenseTypeId: string) =>
      `${getBaseUrl}/api/license-type/${licenseTypeId}/metadata`,
    update: (licenseTypeId: string, id: string) =>
      `${getBaseUrl}/api/license-type/${licenseTypeId}/metadata/${id}`,
    delete: (id: string) => `${getBaseUrl}/api/license-type/metadata/${id}`,
  },
  licensingAuthority: {
    index: (params?: some) =>
      `${getBaseUrl}/api/licensing-authority?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/licensing-authority/${id}`,
    create: `${getBaseUrl}/api/licensing-authority`,
  },
  agency: {
    index: (params?: some) =>
      `${getBaseUrl}/api/agency?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/agency/${id}`,
    create: `${getBaseUrl}/api/agency`,
  },
  enterprise: {
    index: (params?: some) =>
      `${getBaseUrl}/api/enterprise?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/enterprise/${id}`,
    create: `${getBaseUrl}/api/enterprise`,
  },
  construction: {
    index: (params?: some) =>
      `${getBaseUrl}/api/construction?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    userConstruction: (params?: some) =>
      `${getBaseUrl}/api/construction/user-construction?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    userConstructionUpdate: `${getBaseUrl}/api/user-construction`,
    update: (id: string) => `${getBaseUrl}/api/construction/${id}`,
    create: `${getBaseUrl}/api/construction`,
    flushForm: `${getBaseUrl}/api/construction/flushForm`,
    size: `${getBaseUrl}/api/construction/constructionSize`,
  },
  constructionMetaData: {
    index: (constructionId: string, params?: some) =>
      `${getBaseUrl}/api/construction/${constructionId}/metadata?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/metadata/${id}`,
    getById: (id: string) => `${getBaseUrl}/api/construction/metadata/${id}`,
  },
  constructionIndicator: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator/${id}`,
    getById: (id: string) => `${getBaseUrl}/api/construction/indicator/${id}`,
  },
  indicatorsThreshold: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator-threshold`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator-threshold/${id}`,
    getById: (id: string) =>
      `${getBaseUrl}/api/construction/indicator-threshold/${id}`,
  },
  station: {
    index: (params?: some) =>
      `${getBaseUrl}/api/station?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/station/${id} `,
    detail: (id: string) => `${getBaseUrl}/api/station/${id} `,
    create: `${getBaseUrl}/api/station`,
  },

  constructionSensor: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/sensor`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/sensor/${id}`,
  },
  stationIndicators: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/indicator/${id}`,
    delete: (id: string) => `${getBaseUrl}/api/construction/indicator/${id}`,
    censorship: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/censorship`,
    censorshipUpdate: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/censorship/${id}`,
  },

  ftp: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/ftp`,
    directory: `${getBaseUrl}/api/construction/directory-structure`,
  },
  mqtt: {
    index: (stationId: string) =>
      `${getBaseUrl}/api/construction/${stationId}/mqtt`,
  },
  dataLoggerStation: {
    index: (constructionId: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/datalogger`,
    update: (constructionId: string, id: string) =>
      `${getBaseUrl}/api/construction/${constructionId}/datalogger/${id}`,
    delete: (id: string) => `${getBaseUrl}/api/construction/datalogger/${id}`,
  },
  cameraStation: {
    index: (stationId: string) =>
      `${getBaseUrl}/api/station/${stationId}/camera`,
    update: (stationId: string, id: string) =>
      `${getBaseUrl}/api/station/${stationId}/camera/${id}`,
  },
  constructionType: {
    index: (params?: some) =>
      `${getBaseUrl}/api/construction-type?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/construction-type/${id}`,
    create: `${getBaseUrl}/api/construction-type`,
  },
  periodic: {
    index: (type: string, params?: some) =>
      `${getBaseUrl}/api/periodic-monitoring/${type}?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    create: (type: string) => `${getBaseUrl}/api/periodic-monitoring/${type}`,
    update: (type: string, id: string) =>
      `${getBaseUrl}/api/periodic-monitoring/${type}/${id}`,
    indicators: (constructionType: string) =>
      `${getBaseUrl}/api/periodic-monitoring/${constructionType}/indicators`,
    indicatorsContruction: (constructionId: string) =>
      `${getBaseUrl}/api/periodic-monitoring/${constructionId}/indicators/construction`,
    constructions: (constructionId: string, params?: some) =>
      `${getBaseUrl}/api/periodic-monitoring/construction/${constructionId}?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    sampleFile: `${getBaseUrl}/api/periodic-monitoring/import-excel-form`,
    uploadSampleFile: (constructionId: string) =>
      `${getBaseUrl}/api/periodic-monitoring/import-excel?constructionId=${constructionId}`,
  },
  waterQuality: {
    index: (params?: some) =>
      `${getBaseUrl}/api/water-quality?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    update: (id: string) => `${getBaseUrl}/api/water-quality/${id}`,
    create: `${getBaseUrl}/api/water-quality`,
  },
  waterQualityIndicators: {
    index: (waterQualityId: string) =>
      `${getBaseUrl}/api/water-quality/${waterQualityId}/indicator`,
    update: (waterQualityId: string, id: string) =>
      `${getBaseUrl}/api/water-quality/${waterQualityId}/indicator/${id}`,
    delete: (id: string) => `${getBaseUrl}/api/water-quality/indicator/${id}`,
  },
  constructionTypeMetaData: {
    index: (id: string) => `${getBaseUrl}/api/construction-type/${id}/metadata`,
  },
  constructionTypeIndicator: {
    index: (constructionTypeId: string) =>
      `${getBaseUrl}/api/construction-type/${constructionTypeId}/indicator`,
    addMul: (constructionTypeId: string) =>
      `${getBaseUrl}/api/construction-type/${constructionTypeId}/indicator/add-multiple`,
    update: (constructionTypeId: string, id: string) =>
      `${getBaseUrl}/api/construction-type/${constructionTypeId}/indicator/${id}`,
  },
  searchData: {
    minute: (params: some) =>
      `${getBaseUrl}/api/searching-data/minute?${stringify(
        mappedFilter(params)
      )}`,
    minuteMinMax: (params: some) =>
      `${getBaseUrl}/api/searching-data/minute/max-min?${stringify(
        mappedFilter(params)
      )}`,
    minuteExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/minute/export-excel?${stringify(
        params
      )}`,
    minuteChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/minute/chart?${stringify(
        mappedFilter(params)
      )}`,
    day: (params: some) =>
      `${getBaseUrl}/api/searching-data/day?${stringify(mappedFilter(params))}`,
    dayMinMax: (params: some) =>
      `${getBaseUrl}/api/searching-data/day/max-min?${stringify(
        mappedFilter(params)
      )}`,
    dayExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/day/export-excel?${stringify(
        mappedFilter(params)
      )}`,
    dayChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/day/chart?${stringify(
        mappedFilter(params)
      )}`,
    month: (params: some) =>
      `${getBaseUrl}/api/searching-data/month?${stringify(
        mappedFilter(params)
      )}`,
    monthMinMax: (params: some) =>
      `${getBaseUrl}/api/searching-data/month/max-min?${stringify(
        mappedFilter(params)
      )}`,
    monthExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/month/export-excel?${stringify(
        params
      )}`,
    monthChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/month/chart?${stringify(
        mappedFilter(params)
      )}`,
    year: (params: some) =>
      `${getBaseUrl}/api/searching-data/year?${stringify(
        mappedFilter(params)
      )}`,
    yearMinMax: (params: some) =>
      `${getBaseUrl}/api/searching-data/year/max-min?${stringify(
        mappedFilter(params)
      )}`,
    yearExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/year/export-excel?${stringify(
        mappedFilter(params)
      )}`,
    yearChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/year/chart?${stringify(
        mappedFilter(params)
      )}`,
    indicator: (params: some) =>
      `${getBaseUrl}/api/searching-data/indicators?${stringify(
        mappedFilter(params)
      )}`,
  },
  trafficData: {
    day: (params: some) =>
      `${getBaseUrl}/api/searching-data/day-total-traffic?${stringify(
        mappedFilter(params)
      )}`,
    dayExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/day-total-traffic/export-excel?${stringify(
        params
      )}`,
    dayChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/day-total-traffic/chart?${stringify(
        params
      )}`,
    month: (params: some) =>
      `${getBaseUrl}/api/searching-data/month-total-traffic?${stringify(
        params
      )}`,
    monthExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/month-total-traffic/export-excel?${stringify(
        params
      )}`,
    monthChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/month-total-traffic/chart?${stringify(
        params
      )}`,
    year: (params: some) =>
      `${getBaseUrl}/api/searching-data/year-total-traffic?${stringify(
        params
      )}`,
    yearExcel: (params: some) =>
      `${getBaseUrl}/api/searching-data/year-total-traffic/export-excel?${stringify(
        params
      )}`,
    yearChart: (params: some) =>
      `${getBaseUrl}/api/searching-data/year-total-traffic/chart?${stringify(
        params
      )}`,
  },
  qa_qc: {
    get: (params: some) =>
      `${getBaseUrl}/api/qa-qc?${stringify(mappedFilter(params))}`,
    censor: (constructionId: some) =>
      `${getBaseUrl}/api/qa-qc/censor/${constructionId}`,
    approve: (constructionId: some) =>
      `${getBaseUrl}/api/qa-qc/approve/${constructionId}`,
    reject: (constructionId: some) =>
      `${getBaseUrl}/api/qa-qc/reject/${constructionId}`,
    indicators: (params: some) =>
      `${getBaseUrl}/api/qa-qc/indicators?${stringify(mappedFilter(params))}`,
  },

  user: {
    index: (params?: some) =>
      `${getAuthUrl}/api/user/get-users?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    create: `${getAuthUrl}/api/user/create-user`,
    get: `${getAuthUrl}/api/user/get-user-profile`,
    delete: `${getAuthUrl}/api/user/delete-user`,
    getRole: `${getAuthUrl}/api/user/get-users-in-role`,
    changePassword: `${getAuthUrl}/api/change_password_by_admin`,
  },

  role: {
    getAllRole: (params?: some) =>
      `${getAuthUrl}/api/role/get-all-role?${stringify(mappedFilter(params), {
        arrayFormat: "comma",
        skipEmptyString: true,
        skipNull: true,
      })}`,
    getDetail: (params?: some) =>
      `${getAuthUrl}/api/role/get-role-detail?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    create: `${getAuthUrl}/api/role/create-role`,
    update: `${getAuthUrl}/api/role/update-role`,
    addRight: `${getAuthUrl}/api/role/add-right`,
    deleteRole: `${getAuthUrl}/api/role/delete-role`,

    getRight: `${getAuthUrl}/api/right/get-rights`,
    userInRole: (params?: some) =>
      `${getAuthUrl}/api/user/get-users-in-role?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    addUser: `${getAuthUrl}/api/role/add-user`,
    removeUser: `${getAuthUrl}/api/role/remove-user`,
  },
  menu: {
    getAll: (params?: some) =>
      `${getAuthUrl}/api/menu/get-menu-by-app-id?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    create: `${getAuthUrl}/api/menu/create-menu`,
    update: `${getAuthUrl}/api/menu/update-menu`,
    delete: `${getAuthUrl}/api/menu/delete-menu`,
  },
  job: {
    delete: `${getAuthUrl}/api/scheduler/delete-job`,
    getAll: `${getAuthUrl}/api/scheduler/get-all-job`,
    getDetail: `${getAuthUrl}/api/scheduler/get-job-detail`,
    getLog: (params?: some) =>
      `${getAuthUrl}/api/scheduler/get-scheduler-log?${stringify(
        mappedFilter(params),
        {
          arrayFormat: "comma",
          skipEmptyString: true,
          skipNull: true,
        }
      )}`,
    pause: `${getAuthUrl}/api/scheduler/pause-job`,
    register: `${getAuthUrl}/api/scheduler/register-job`,
    resume: `${getAuthUrl}/api/scheduler/resume-job`,
    start: `${getAuthUrl}/api/scheduler/start-job-now`,
    unschedule: `${getAuthUrl}/api/scheduler/unschedule-job`,
    update: `${getAuthUrl}/api/scheduler/update-job`,
  },
  configPublic: {
    index: (params: some) =>
      `${getBaseUrl}/api/config-public?${stringify(mappedFilter(params))}`,
    update: `${getBaseUrl}/api/config-public`,
  },
};
