import { LockReset } from "@mui/icons-material";
import ExitToAppOutlined from "@mui/icons-material/ExitToAppOutlined";
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import NightlightOutlinedIcon from "@mui/icons-material/NightlightOutlined";
import NotificationsNoneRounded from "@mui/icons-material/NotificationsNoneRounded";
import {
  alpha,
  Avatar,
  Badge,
  Box,
  ButtonBase,
  ClickAwayListener,
  Divider,
  Fade,
  IconButton,
  Paper,
  Popper,
  Theme,
  Typography,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import Cookies from "js-cookie";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import LinkPM from "../common/components/LinkPM";
import { MODE_THEME } from "../common/constants";
import { setModeTheme } from "../common/reducer/commonReducer";
import { LIGHT_COLOR_LAYOUT } from "../config/colors";
import LanguageSelect from "../intl/component/LanguageSelect";
import { logoutThunk } from "../modules/auth/reducer/authenticationReducer";
import { AppState } from "../redux/reducer";
import { LogoHaGiang } from "../svg";
import { HEADER_HEIGHT, ROUTES } from "./constants";

const useStyles = makeStyles(() => ({
  button: {
    width: "100%",
    padding: "8px 24px",
    justifyContent: "flex=start",
    textAlign: "start",
  },
  icon: {
    height: 16,
    color: grey[600],
  },
  collapse: {
    position: "absolute",
    width: 220,
    color: "black",
    zIndex: 110,
    top: 52,
    right: 40,
  },
  paper: {
    overflow: "hidden",
    width: 240,
    padding: 0,
    marginTop: 16,
    marginRight: 16,
  },
}));
interface Props {
  openAside: boolean;
}

const Header: React.FunctionComponent<Props> = ({ openAside }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const user = useSelector((state: AppState) => state.auth.userInfo);
  const mode = useSelector((state: AppState) => state.common.mode);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );

  const handleClickMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElMenu(anchorElMenu ? null : event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchorElMenu(null);
  };
  const openMenu = Boolean(anchorElMenu);

  return (
    <>
      <Box
        display="flex"
        alignItems="center"
        boxSizing="border-box"
        position="fixed"
        zIndex={200}
        top={0}
        right={0}
        left={0}
        paddingX={2}
        paddingY={1}
        height={HEADER_HEIGHT}
        boxShadow={(theme: Theme) => theme.shadows[1]}
        bgcolor={(theme: Theme) =>
          alpha(
            theme.palette.mode === "light"
              ? LIGHT_COLOR_LAYOUT
              : theme.palette.background.default,
            0.72
          )
        }
        style={{
          backdropFilter: "blur(6px)",
        }}
      >
        <Box flex={1} display="flex" gap={1}>
          <Avatar src={LogoHaGiang} />
          <Box>
            <Typography
              variant="subtitle2"
              style={{ textTransform: "uppercase" }}
            >
              <FormattedMessage id="hg_title" />
            </Typography>
            <Typography variant="caption">
              <FormattedMessage id="hg_label" />
            </Typography>
          </Box>
        </Box>
        <Box display="flex" alignItems="center" justifyContent="flex-end">
          <IconButton
            onClick={() => {
              Cookies.set(MODE_THEME, mode === "dark" ? "light" : "dark");
              dispatch(setModeTheme(mode === "dark" ? "light" : "dark"));
            }}
          >
            {mode === "dark" ? (
              <LightModeOutlinedIcon style={{ color: "white" }} />
            ) : (
              <NightlightOutlinedIcon style={{ color: "white" }} />
            )}
          </IconButton>
          <IconButton style={{ margin: "0px 8px" }}>
            <Badge badgeContent={4} color="error">
              <NotificationsNoneRounded style={{ color: "white" }} />
            </Badge>
          </IconButton>
          <LanguageSelect />
          <IconButton
            onClick={handleClickMenu}
            style={{ padding: 0, marginLeft: 24 }}
          >
            <Avatar alt="User" style={{ textTransform: "uppercase" }}>
              {user?.name}
            </Avatar>
          </IconButton>
        </Box>
      </Box>
      <Popper
        open={openMenu}
        anchorEl={anchorElMenu}
        transition
        style={{ zIndex: 100 }}
      >
        {({ TransitionProps }) => (
          <ClickAwayListener onClickAway={handleCloseMenu}>
            <Fade {...TransitionProps} timeout={350}>
              <Paper className={classes.paper}>
                <Box padding={2}>
                  <Typography variant="h6" color="primary">
                    {user?.name}
                  </Typography>
                  <Typography variant="body2" style={{ marginTop: 16 }}>
                    {user?.email}
                  </Typography>
                </Box>
                <Divider />
                <LinkPM to={ROUTES.changePassword}>
                  <ButtonBase
                    className={classes.button}
                    style={{ padding: "8px 16px" }}
                  >
                    <Box flex={1}>
                      <Typography variant="body2">
                        <FormattedMessage id="changePassword" />
                      </Typography>
                    </Box>
                    <LockReset className={classes.icon} />
                  </ButtonBase>
                </LinkPM>
                <Divider />
                <ButtonBase
                  className={classes.button}
                  onClick={() => {
                    dispatch(logoutThunk());
                  }}
                  style={{ padding: "8px 16px" }}
                >
                  <Box flex={1}>
                    <Typography variant="body2">
                      <FormattedMessage id="logout" />
                    </Typography>
                  </Box>
                  <ExitToAppOutlined className={classes.icon} />
                </ButtonBase>
              </Paper>
            </Fade>
          </ClickAwayListener>
        )}
      </Popper>
    </>
  );
};

export default Header;
