import { FormattedMessage } from "react-intl";
import { Location, Navigate } from "react-router";
import AgencyPage from "../modules/agency/page/AgencyPage";
import AreaPage from "../modules/area/page/AreaPage";
import ChangePasswordPage from "../modules/auth/page/ChangePasswordPage";
import CommunePage from "../modules/commune/page/CommunePage";
import ConstructionDetailPage from "../modules/construction/page/ConstructionDetailPage";
import ConstructionPage from "../modules/construction/page/ConstructionPage";
import ConstructionTypeDetailPage from "../modules/constructionType/page/ConstructionTypeDetailPage";
import ConstructionTypePage from "../modules/constructionType/page/ConstructionTypePage";
import DashboardPage from "../modules/dashboard/page/DashboardPage";
import DataLoggerPage from "../modules/datalogger/page/DataLoggerPage";
import DistrictPage from "../modules/district/page/DistrictPage";
import EnterprisePage from "../modules/enterprise/page/EnterprisePage";
import IndicatorPage from "../modules/indicator/page/IndicatorPage";
import JobPage from "../modules/job/page/JobPage";
import LicenseDetailPage from "../modules/license/page/LicenseDetailPage";
import LicensePage from "../modules/license/page/LicensePage";
import LicensingAuthorityPage from "../modules/licenseAuthority/page/LicensingAuthorityPage";
import LicenseTypePage from "../modules/licenseType/page/LicenseTypePage";
import LicenseTypePageDetailPage from "../modules/licenseType/page/LicenseTypePageDetailPage";
import MapConstructionPage from "../modules/mapStation/page/MapConstructionPage";
import MenuPage from "../modules/menu/page/MenuPage";
import MonitorOnlinePage from "../modules/monitorOnline/page/MonitorOnlinePage";
import NreFieldPage from "../modules/nreField/page/NreFieldPage";
import NreTypePage from "../modules/nreType/page/NreTypePage";
import ConstructionPeriodicMonitoringPage from "../modules/periodicMonitoring/page/ConstructionPeriodicMonitoringPage";
import PeriodicMonitoringPage from "../modules/periodicMonitoring/page/PeriodicMonitoringPage";
import ProvincePage from "../modules/province/page/ProvincePage";
import PublicConfigPage from "../modules/publicConfig/page/PublicConfigPage";
import QAQCPage from "../modules/qa_qc/page/QAQCPage";
import ReportDetailPage from "../modules/report/page/ReportDetailPage";
import ReportPage from "../modules/report/page/ReportPage";
import RiverBasinPage from "../modules/riverBasin/page/RiverBasinPage";
import RoleDetailPage from "../modules/role/page/RoleDetailPage";
import RolePage from "../modules/role/page/RolePage";
import SearchDataPageMinute from "../modules/searchDataMinute/page/SearchDataPageMinute";
import SensorPage from "../modules/sensor/page/SensorPage";
import UserConstructionPage from "../modules/user/page/UserConstructionPage";

import UserPage from "../modules/user/page/UserPage";
import { AppState } from "../redux/reducer";
import {
  AdministrativeIcon,
  CalendarIcon,
  CensorshipIcon,
  ConstructionIcon,
  CubeIcon,
  Dashboard,
  FileIcon,
  MiningData,
  MonitorOnline,
  Report,
  Setting,
} from "../svg";
import { ROUTES } from "./constants";
import { IRouteObject } from "./utils";

export const ADMIN_ROUTES = (
  appState: AppState,
  location: Location
): IRouteObject[] => [
  {
    path: "*",
    element: <Navigate to={ROUTES.dashboard.index} />,
    hidden: true,
  },
  {
    path: ROUTES.changePassword,
    title: "changePassword",
    fixedRoute: true,
    element: <ChangePasswordPage />,
    hidden: true,
  },
  {
    path: ROUTES.dashboard.index,
    title: "nav.summary",
    icon: <Dashboard className="svgStroke" />,
    children: [
      {
        path: ROUTES.dashboard.summary,
        title: "nav.dashboard",
        element: <DashboardPage />,
      },
      {
        path: ROUTES.dashboard.mapConstruction,
        title: "nav.mapConstruction",
        element: <MapConstructionPage />,
      },
      {
        path: "*",
        hidden: true,
        element: <Navigate to={ROUTES.dashboard.mapConstruction} />,
      },
    ],
  },
  {
    title: "nav.monitorOnline",
    icon: <MonitorOnline className="svgStroke" />,
    path: ROUTES.monitorOnline.index,
    children: [
      ...((appState?.nre?.data || [])?.map((item) => {
        return {
          path: ROUTES.monitorOnline.gen(item.id),
          staticRoute: true,
          title: (
            <FormattedMessage
              id="nav.monitorOnlineName"
              values={{ name: item.name }}
            />
          ),
        };
      }) as any),
      {
        path: ROUTES.monitorOnline.detail,
        hidden: true,
        title: (
          <FormattedMessage
            id="nav.monitorOnlineName"
            values={{
              name:
                appState?.nre?.data?.find((v) =>
                  location.pathname.includes(v.id)
                )?.name || "",
            }}
          />
        ),
        element: <MonitorOnlinePage />,
      },
    ],
  },
  {
    title: "nav.periodicMonitoring",
    icon: <CalendarIcon className="svgStroke" />,
    path: ROUTES.periodicMonitoring.index,
    children: [
      ...((appState?.nre?.data || [])
        ?.filter((v) => v.isPeriodic)
        ?.map((item) => {
          return {
            path: ROUTES.periodicMonitoring.gen(item.id),
            staticRoute: true,
            title: (
              <FormattedMessage
                id="nav.monitorOnlineName"
                values={{ name: item.name }}
              />
            ),
          };
        }) as any),
      {
        path: ROUTES.periodicMonitoring.detail,
        hidden: true,
        title: (
          <FormattedMessage
            id="nav.monitorOnlineName"
            values={{
              name:
                appState?.nre?.data?.find((v) =>
                  location.pathname.includes(v.id)
                )?.name || "",
            }}
          />
        ),
        children: [
          {
            element: <PeriodicMonitoringPage />,
            index: true,
            hidden: true,
          },
          {
            path: ROUTES.periodicMonitoring.history,
            title: "nav.periodicMonitoringHistory",
            element: <ConstructionPeriodicMonitoringPage />,
            hidden: true,
          },
        ],
      },
      // {
      //   path: ROUTES.periodicMonitoring.waterQuality.index,
      //   title: <FormattedMessage id="nav.waterQuality" />,
      //   children: [
      //     {
      //       index: true,
      //       element: <WaterQualityPage />,
      //       hidden: true,
      //     },
      //     {
      //       title: "detail",
      //       path: ROUTES.periodicMonitoring.waterQuality.detail,
      //       element: <WaterQualityDetailPage />,
      //       hidden: true,
      //     },
      //   ],
      // },
    ],
  },

  {
    title: "nav.report",
    icon: <Report className="svgStroke" />,
    path: ROUTES.report.index,
    children: [
      {
        index: true,
        element: <ReportPage />,
        hidden: true,
      },
      {
        title: "nav.reportDetail",
        path: ROUTES.report.detail,
        element: <ReportDetailPage />,
        hidden: true,
      },
    ],
  },
  {
    title: "nav.qa_qc",
    icon: <CensorshipIcon />,
    path: ROUTES.censorship.index,
    element: <QAQCPage />,
  },
  {
    path: ROUTES.searchingData.index,
    title: "nav.searchingData",
    icon: <MiningData className="svgStroke" />,
    children: [
      // {
      //   path: ROUTES.searchingData.license.index,
      //   title: "nav.searchingLicense",
      //   children: [
      //     {
      //       index: true,
      //       element: <SearchingLicensePage />,
      //     },
      //     {
      //       path: ROUTES.searchingData.license.detail,
      //       title: "license.detail",
      //       hidden: true,
      //       element: <SearchingLicenseDetailPage />,
      //     },
      //   ],
      // },
      {
        path: ROUTES.searchingData.minute,
        title: "miningData.minute",
        element: <SearchDataPageMinute />,
      },
      // {
      //   path: ROUTES.searchingData.hour,
      //   title: "miningData.hour",
      //   element: <SearchDataPageHour />,
      // },
      // {
      //   path: ROUTES.searchingData.day,
      //   title: "miningData.day",
      //   element: <SearchDataPageDay />,
      // },
      // {
      //   path: ROUTES.searchingData.month,
      //   title: "miningData.month",
      //   element: <SearchDataPageMonth />,
      // },
      // {
      //   path: ROUTES.searchingData.year,
      //   title: "miningData.year",
      //   element: <SearchDataPageYear />,
      // },
      // {
      //   path: ROUTES.searchingData.trafficDay,
      //   title: "totalTraffic.day",
      //   element: <TrafficDataPageDay />,
      // },
      // {
      //   path: ROUTES.searchingData.trafficMonth,
      //   title: "totalTraffic.month",
      //   element: <TrafficDataPageMonth />,
      // },
      // {
      //   path: ROUTES.searchingData.trafficYear,
      //   title: "totalTraffic.year",
      //   element: <TrafficDataPageYear />,
      // },
      {
        path: "*",
        hidden: true,
        element: <Navigate to={ROUTES.searchingData.minute} />,
      },
    ],
  },
  {
    title: "nav.constructionManager",
    icon: <ConstructionIcon className="svgStroke" />,
    path: ROUTES.constructionManager.index,
    children: [
      {
        path: ROUTES.constructionManager.construction.index,
        title: "nav.construction",
        children: [
          {
            index: true,
            element: <ConstructionPage />,
          },
          {
            path: ROUTES.constructionManager.construction.detail,
            title: "detail",
            hidden: true,
            element: <ConstructionDetailPage />,
          },
        ],
      },
      {
        path: "*",
        element: (
          <Navigate to={ROUTES.constructionManager.construction.index} />
        ),
        hidden: true,
      },
    ],
  },
  {
    title: "nav.licenseManagement",
    icon: <FileIcon className="svgStroke" />,
    path: ROUTES.licenseManagement.index,
    hidden: true,
    children: [
      {
        path: ROUTES.licenseManagement.list,
        title: "nav.license",
        children: [
          {
            index: true,
            element: <LicensePage />,
          },
          {
            path: ROUTES.licenseManagement.detail,
            element: <LicenseDetailPage />,
            title: "detail",
            hidden: true,
          },
        ],
      },

      {
        path: ROUTES.licenseManagement.licensingAuthority,
        element: <LicensingAuthorityPage />,
        title: "nav.licensingAuthority",
      },

      {
        path: "*",
        element: <Navigate to={ROUTES.licenseManagement.list} />,
        hidden: true,
      },
    ],
  },
  {
    title: "nav.administrative",
    icon: <AdministrativeIcon className="svgStroke" />,
    path: ROUTES.administrative.index,
    children: [
      {
        path: ROUTES.administrative.province,
        element: <ProvincePage />,
        title: "nav.province",
      },
      {
        path: ROUTES.administrative.district,
        element: <DistrictPage />,
        title: "nav.district",
      },
      {
        path: ROUTES.administrative.commune,
        element: <CommunePage />,
        title: "nav.commune",
      },

      {
        path: ROUTES.administrative.area,
        element: <AreaPage />,
        title: "nav.area",
      },
      {
        path: ROUTES.administrative.riverBasin,
        element: <RiverBasinPage />,
        title: "nav.riverBasin",
      },

      {
        path: "*",
        element: <Navigate to={ROUTES.administrative.province} />,
        hidden: true,
      },
    ],
  },

  {
    title: "nav.document",
    icon: <CubeIcon className="svgStroke" />,
    path: ROUTES.document.index,
    children: [
      {
        path: ROUTES.document.nreField,
        element: <NreFieldPage />,
        title: "nav.nreField",
      },
      {
        path: ROUTES.document.nreType,
        element: <NreTypePage />,
        title: "nav.nreType",
      },
      {
        path: ROUTES.document.indicator,
        element: <IndicatorPage />,
        title: "nav.indicator",
      },
      {
        path: ROUTES.document.constructionType.index,
        title: "nav.constructionType",
        children: [
          {
            index: true,
            element: <ConstructionTypePage />,
          },
          {
            path: ROUTES.document.constructionType.detail,
            element: <ConstructionTypeDetailPage />,
            title: "detail",
            hidden: true,
          },
        ],
      },
      {
        path: ROUTES.document.licenseType.index,
        title: "nav.licenseType",
        hidden: true,
        children: [
          {
            index: true,
            element: <LicenseTypePage />,
          },
          {
            path: ROUTES.document.licenseType.detail,
            element: <LicenseTypePageDetailPage />,
            title: "detail",
            hidden: true,
          },
        ],
      },
      {
        path: ROUTES.document.sensor,
        element: <SensorPage />,
        title: "nav.sensor",
      },
      {
        path: ROUTES.document.dataLogger,
        element: <DataLoggerPage />,
        title: "nav.dataLogger",
      },
      // {
      //   path: ROUTES.document.camera,
      //   element: <CameraPage />,
      //   title: "nav.camera",
      // },
      // {
      //   path: ROUTES.document.analytical,
      //   element: <AnalyticalMethodPage />,
      //   title: "nav.analytical",
      // },

      {
        path: ROUTES.document.agency,
        element: <AgencyPage />,
        title: "nav.agency",
      },
      {
        path: ROUTES.document.enterprise,
        element: <EnterprisePage />,
        title: "nav.enterprise",
      },

      // {
      //   path: ROUTES.document.qcvn.index,
      //   title: "nav.qcvn",
      //   children: [
      //     {
      //       index: true,
      //       element: <QCVNPage />,
      //     },
      //     {
      //       path: ROUTES.document.qcvn.detail,
      //       element: <QCVNDetailPage />,
      //       title: "detail",
      //       hidden: true,
      //     },
      //   ],
      // },

      {
        path: "*",
        element: <Navigate to={ROUTES.document.camera} />,
        hidden: true,
      },
    ],
  },
  // {
  //   title: "nav.monitorOnline",
  //   icon: <MonitorOnline className="svgStroke" />,
  //   path: ROUTES.monitorOnline.index,
  //   children: [
  //     {
  //       path: ROUTES.monitorOnline.surfaceWater,
  //       title: "nav.surfaceWater",
  //       element: <></>,
  //     },
  //     {
  //       path: ROUTES.monitorOnline.groundwater,
  //       title: "nav.groundwater",
  //       element: <></>,
  //     },
  //     {
  //       path: ROUTES.monitorOnline.seaWater,
  //       title: "nav.seaWater",
  //       element: <></>,
  //     },
  //     {
  //       path: ROUTES.monitorOnline.wasterWater,
  //       title: "nav.wasterWater",
  //       element: <></>,
  //     },
  //   ],
  // },
  {
    title: "nav.configSystem",
    icon: <Setting className="svgStroke" />,
    path: ROUTES.configSystem.index,
    children: [
      {
        path: ROUTES.configSystem.user,
        title: "user",
        children: [
          {
            index: true,
            element: <UserPage />,
          },
          {
            path: ROUTES.configSystem.detail,
            hidden: true,
            title: "detail",
            element: <UserConstructionPage />,
          },
        ],
      },
      {
        path: ROUTES.configSystem.job,
        element: <JobPage />,
        title: "job",
      },
      {
        path: ROUTES.configSystem.menu,
        element: <MenuPage />,
        title: "menu",
      },
      {
        path: ROUTES.configSystem.role,
        title: "role",
        children: [
          {
            index: true,
            hidden: true,
            element: <RolePage />,
          },
          {
            path: ROUTES.configSystem.detail,
            hidden: true,
            title: "detail",
            element: <RoleDetailPage />,
          },
        ],
      },
      {
        path: ROUTES.configSystem.publicConfig,
        element: <PublicConfigPage />,
        title: "publicConfig",
      },
    ],
  },
];
