export type some = { [key: string]: any };

export const TOKEN = "token-key";
export const MODE_THEME = "mode_theme";
export const PAGE_SIZE = "page_size";
export const SUCCESS_CODE = 0;
export const KEY_GOOGLE_MAP = "AIzaSyCK_8jKvtRQ4-j9RhlNhJb0NeUV4aiEmaY";
export const DATE_TIME_FORMAT = "HH:mm DD/MM/YYYY";
export const DATE_TIME_FULL_FORMAT = "HH:mm:ss DD/MM/YYYY";
export const DATE_FORMAT = "DD/MM/YYYY";
export const TIME_FORMAT = "HH:mm";
export const BE_DATE_FORMAT = "YYYY-MM-DD";
export const BE_DATE_TIME_FORMAT = "YYYY-MM-DD HH:mm:ss";
export const STATION_WIND_OPTIONS = [
  { id: "1", name: "Trạm đo 1" },
  { id: "2", name: "Trạm đo 2" },
  { id: "3", name: "Trạm đo 3" },
  { id: "4", name: "Trạm đo 4" },
  { id: "5", name: "Trạm đo 5" },
];
