import * as React from "react";
import { FormattedMessage } from "react-intl";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  formData?: some;
  onSubmit: (value: some, setLoading: (loading: boolean) => void) => void;
  submitLabel?: string;
  onCancel?: () => void;
}

const FormInfoBox: React.FunctionComponent<Props> = (props) => {
  const { formData, onSubmit, submitLabel, onCancel } = props;

  return (
    <SchemaForm
      initialData={formData}
      onCancel={onCancel}
      onSubmit={onSubmit}
      formProps={{
        autoComplete: "nope",
        autoCorrect: "off",
      }}
      submitLabel={submitLabel && <FormattedMessage id={submitLabel} />}
      schema={{
        fields: ({ formProps: { intl } }) => {
          return {
            name: {
              type: "text-field",
              label: intl.formatMessage({ id: "licenseType.name" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              register: {
                required: true,
              },
            },
            sortOrder: {
              type: "text-field",
              inputType: "number",
              label: intl.formatMessage({ id: "sortOrder" }),
              placeholder: intl.formatMessage({ id: "enter" }),
            },
            description: {
              type: "text-field",
              label: intl.formatMessage({ id: "description" }),
              placeholder: intl.formatMessage({ id: "enter" }),
              multiline: true,
              rows: 5,
            },
          };
        },
      }}
    />
  );
};

export default FormInfoBox;
