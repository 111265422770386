export const ASIDE_WIDTH = 256;
export const ASIDE_MIN_WIDTH = 48;
export const HEADER_HEIGHT = 56;
export const ASIDE_ITEM_HEIGHT = 56;

export const ROUTES = {
  login: "login",
  changePassword: "changePassword",
  dashboard: {
    index: "dashboard",
    mapConstruction: "map-construction",
    mapAQI: "mapAQI",
    summary: "summary",
  },
  monitorOnline: {
    index: "monitor-online",
    detail: ":nreTypeId",
    gen: (id: string) => `/monitor-online/${id}`,
  },
  cameraOnline: {
    index: "camera-online",
    detail: ":nreTypeId",
    gen: (id: string) => `/camera-online/${id}`,
  },
  periodicMonitoring: {
    index: "periodic",
    detail: ":nreTypeId",
    history: ":constructionId",
    gen: (id: string) => `/periodic/${id}`,
    waterQuality: { index: "water-quality", detail: ":id" },
  },
  searchingData: {
    index: "searching-data",
    minute: "minute",
    day: "day",
    month: "month",
    year: "year",
    trafficDay: "traffic-day",
    trafficMonth: "traffic-month",
    trafficYear: "traffic-year",
    license: { index: "license", detail: ":id" },
  },
  constructionManager: {
    index: "construction-manager",
    construction: {
      index: "construction",
      detail: ":id",
    },
  },
  stationManager: {
    index: "station",
    station: { index: "list", detail: ":id" },
    stationType: { index: "type", detail: ":id" },
  },
  administrative: {
    index: "administrative",
    province: "province",
    district: "district",
    commune: "commune",
    area: "area",
    riverBasin: "riverBasin",
  },
  licenseManagement: {
    index: "license",
    list: "list",
    detail: ":id",
    licensingAuthority: "licensing-authority",
  },
  document: {
    index: "document",
    nreField: "nre-field",
    nreType: "nreType",
    indicator: "indicator",
    camera: "camera",
    sensor: "sensor",
    dataLogger: "data-logger",
    analytical: "analytical",
    agency: "agency",
    enterprise: "enterprise",
    monitoringLocation: "location-monitoring",
    constructionType: { index: "construction-type", detail: ":id" },
    licenseType: { index: "type", detail: ":id" },
    qcvn: { index: "qcvn", detail: ":id", threshold: ":thresholdId " },
  },
  configSystem: {
    index: "config-system",
    user: "user",
    role: "role",
    menu: "menu",
    job: "job",
    detail: ":id",
    publicConfig: "publicConfig",
  },
  report: {
    index: "report",
    detail: ":id",
  },
  censorship: { index: "qa_qc" },
};
