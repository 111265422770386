import { Visibility } from "@mui/icons-material";
import { Box, IconButton, Paper, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";

interface Props {}
const Table = (props: Props) => {
  return (
    <>
      <Paper sx={{ p: 2 }}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">
            <FormattedMessage id="report.list" />
          </Typography>
        </Box>
        <TableCustom
          dataSource={[
            {
              name: "Số lượng trạm quan trắc khí tượng, thủy văn, tài nguyên nước, nước dưới đất",
            },
            { name: "Tổng lượng mưa, phân phối lượng mưa trong năm" },
            { name: "Lượng mưa tháng, năm trong kỳ báo cáo" },
            { name: "Tổng lượng nước mặt trên các lưu vực sông" },
            { name: "Dòng chảy trung bình tháng, năm trong kỳ báo cáo" },
            {
              name: "Tổng hợp dung tích các hồ chứa lớn, quan trọng trên các lưu vực sông",
            },
            {
              name: "Diện tích đã được điều tra, đánh giá tài nguyên nước dưới đất",
            },
            { name: "Tổng hợp các đặc trưng mực nước dưới đất" },
            {
              name: "Báo cáo số lượng công trình khai thác phân theo nguồn nước",
            },
          ]}
          columns={[
            {
              title: "report.number",
              render: (record, index) => {
                return (
                  <FormattedMessage
                    id="report.number2"
                    values={{ num: index + 1 }}
                  />
                );
              },
            },
            { title: "report.name", dataIndex: "name" },
            {
              title: "action",
              align: "center",
              width: 70,
              render: (record, index) => {
                return (
                  <LinkPM to={`${index + 1}`}>
                    <IconButton>
                      <Visibility />
                    </IconButton>
                  </LinkPM>
                );
              },
            },
          ]}
        />
      </Paper>
    </>
  );
};
export default Table;
