import { Box } from "@mui/material";
import Cookies from "js-cookie";
import * as React from "react";
import { API_PATHS } from "../../../api/API_App";
import { SUCCESS_CODE, TOKEN } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../common/reducer/thunk";
import { BannerIcon } from "../../../svg";
import LoginBox, { ILogin } from "../components/LoginBox";
import {
  authenticationIn,
  saveTokenInfo,
  saveUserInfo,
} from "../reducer/authenticationReducer";

interface Props {}
const LoginPage = (props: Props) => {
  const { dispatch, enqueueSnackbar } = useGeneralHook();
  const [loading, setLoading] = React.useState(false);

  const onSubmit = React.useCallback(
    async (values: ILogin) => {
      setLoading(true);
      try {
        const res = await fetch(
          API_PATHS.auth.login({
            grantType: "PASSWORD_GRANT",
            appId: process.env.REACT_APP_APP_ID,
            secret: process.env.REACT_APP_SECRET,
          }),
          {
            method: "get",
            headers: {
              "Cache-Control": "no-cache",
              Authorization: `Basic ${btoa(
                `${values.username}:${values.password}`
              )}`,
              "Content-Type": "application/json",
            },
          }
        );
        const json = await res.json();

        if (json.code === SUCCESS_CODE) {
          Cookies.set(TOKEN, json.data.accessToken);
          dispatch(saveTokenInfo(json.data));
          dispatch(authenticationIn());
          const json2 = await dispatch(fetchThunk(API_PATHS.user.get, "get"));
          dispatch(saveUserInfo(json2.data));
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      } finally {
        setLoading(false);
      }
    },
    [dispatch, enqueueSnackbar]
  );
  return (
    <Box
      display="flex"
      alignItems="center"
      height="100vh"
      bgcolor="background.default"
    >
      <Box flex={1}>
        <BannerIcon style={{ height: "100vh", width: "100%" }} />
      </Box>
      <LoginBox onSubmit={onSubmit} loading={loading} />
    </Box>
  );
};
export default LoginPage;
