import { Add, Delete } from "@mui/icons-material";
import { Box, Button, IconButton, Paper } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";
import SchemaElement from "../../../common/SchemaForm/SchemaElement";
import { mergeFieldName } from "../../../common/SchemaForm/utils";

interface Props {
  submitLabel?: string;
  onSubmitSuccess?: () => void;
  constructionTypeId?: string;
}

const IndicatorCreateBox: React.FunctionComponent<Props> = (props) => {
  const { submitLabel, onSubmitSuccess, constructionTypeId } = props;

  const { dispatch, enqueueSnackbar, fetchThunk, API_PATHS } = useGeneralHook();

  const { data, revalidate } = useSWR(
    constructionTypeId
      ? API_PATHS.constructionTypeIndicator.index(constructionTypeId)
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json;
    }
  );

  const onSubmit = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      if (!constructionTypeId) {
        return;
      }
      setLoading(true);
      const json = await dispatch(
        fetchThunk(
          API_PATHS.constructionTypeIndicator.addMul(constructionTypeId),
          "post",
          value.data
        )
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        onSubmitSuccess && onSubmitSuccess();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.constructionTypeIndicator,
      constructionTypeId,
      dispatch,
      fetchThunk,
      onSubmitSuccess,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <SchemaForm
      fromData={data}
      onSubmit={onSubmit}
      formProps={{
        autoComplete: "nope",
        autoCorrect: "off",
      }}
      submitLabel={submitLabel && <FormattedMessage id={submitLabel} />}
      schema={{
        fields: ({ formProps: { intl } }) => {
          return {
            data: {
              type: "array",
              keyName: "rowId",
              render: ({ methodsArray }) => {
                const { fields, remove, append } = methodsArray;
                return (
                  <>
                    <Paper variant="outlined">
                      <TableCustom
                        dataSource={fields}
                        columns={[
                          {
                            title: "constructionIndicator.name",
                            render: (record, index) => {
                              return (
                                <SchemaElement
                                  key={record.rowId}
                                  fieldName={mergeFieldName({
                                    name: "indicator",
                                    index,
                                    parent: "data",
                                  })}
                                  propsElement={{
                                    type: "auto-complete",
                                    placeholder: intl.formatMessage({
                                      id: "select",
                                    }),
                                    loadOptions: async (name: string) => {
                                      const json = await dispatch(
                                        fetchThunk(
                                          API_PATHS.indicator.index({
                                            name,
                                          })
                                        )
                                      );
                                      return json.data?.items?.map((v) => ({
                                        id: v.id,
                                        name: v.name,
                                        unit: v.unit,
                                        code: v.code,
                                      }));
                                    },
                                    noHelperText: true,
                                    register: { required: true },
                                  }}
                                />
                              );
                            },
                          },
                          {
                            title: "constructionIndicator.isAuto",
                            align: "center",
                            render: (record, index) => {
                              return (
                                <SchemaElement
                                  key={record.rowId}
                                  fieldName={mergeFieldName({
                                    name: "isAuto",
                                    index,
                                    parent: "data",
                                  })}
                                  propsElement={{
                                    type: "checkbox",
                                    noHelperText: true,
                                    fromControlProps: {
                                      style: { marginTop: 0, margin: "auto" },
                                    },
                                    propsWrapper: { xs: undefined },
                                  }}
                                />
                              );
                            },
                          },
                          {
                            title: "constructionIndicator.isPeriodic",
                            align: "center",
                            render: (record, index) => {
                              return (
                                <SchemaElement
                                  key={record.rowId}
                                  fieldName={mergeFieldName({
                                    name: "isPeriodic",
                                    index,
                                    parent: "data",
                                  })}
                                  propsElement={{
                                    type: "checkbox",
                                    noHelperText: true,
                                    fromControlProps: {
                                      style: { marginTop: 0, margin: "auto" },
                                    },
                                    propsWrapper: { xs: undefined },
                                  }}
                                />
                              );
                            },
                          },
                          {
                            title: "action",
                            align: "center",
                            width: 80,
                            render: (_, index) => {
                              return (
                                <IconButton
                                  onClick={() => {
                                    remove(index);
                                  }}
                                  size="small"
                                >
                                  <Delete />
                                </IconButton>
                              );
                            },
                          },
                        ]}
                        fixIndexColumn
                        containerProps={{ style: { maxHeight: 456 } }}
                      />
                      <Box padding={1}>
                        <Button
                          startIcon={<Add />}
                          color="primary"
                          onClick={() =>
                            append({
                              indication: { name: "", code: "", unit: "" },
                            })
                          }
                        >
                          <FormattedMessage id="create" />
                        </Button>
                      </Box>
                    </Paper>
                  </>
                );
              },
            },
          };
        },
      }}
    />
  );
};

export default IndicatorCreateBox;
