import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { Box, Button, IconButton, Paper, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import TableCustom from "../../../../common/components/TableCustom";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { LIST_STATUS_STATION } from "../../constants";
interface Params {
  distributionBy: string;
  station?: some;
}

interface Props {}
const GroupChart = (props: Props) => {
  const { dispatch, API_PATHS, intl, appState } = useGeneralHook();
  const [filterParams, setFilterParams] = useState<Params>({
    distributionBy: "area",
  });

  const { data = [] } = useSWR(
    API_PATHS.dashboard.constructionsDistribution({
      distributionBy: filterParams.distributionBy,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const { data: constructionData, isValidating } = useSWR(
    filterParams.station
      ? API_PATHS.construction.index({
          ...filterParams.station,
          pageSize: 9999999,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const series = useMemo(() => {
    return LIST_STATUS_STATION?.map((item) => {
      return {
        name: intl.formatMessage({ id: item.label }),
        data: data?.map((val) => {
          return val[item.key];
        }),
        color: item.color,
      };
    });
  }, [data, intl]);

  return (
    <Box height="100%" display={"flex"} flexDirection="column">
      <Typography variant="h6">
        <FormattedMessage id="dashboard.constructionDistribution" />
      </Typography>{" "}
      <Paper
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          paddingBottom: 2,
          marginTop: 1.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            padding: 2,
            justifyContent: "flex-end",
          }}
        >
          <Box display="flex" gap={2}>
            <Button
              size="small"
              variant={
                filterParams.distributionBy === "river_basin"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                setFilterParams({
                  distributionBy: "river_basin",
                })
              }
            >
              <FormattedMessage id="riverBasin" />
            </Button>
            <Button
              size="small"
              variant={
                filterParams.distributionBy === "area"
                  ? "contained"
                  : "outlined"
              }
              onClick={() =>
                setFilterParams({
                  distributionBy: "area",
                })
              }
            >
              <FormattedMessage id="areaSearch" />
            </Button>
            {/* <Button
            size="small"
            variant={filterParams.by === "district" ? "contained" : "outlined"}
            onClick={() =>
              setFilterParams({
                by: "district",
              })
            }
          >
            <FormattedMessage id="districtSearch" />
          </Button> */}
          </Box>
        </Box>
        <Box flex={1} overflow={"hidden"}>
          <Box
            display="flex"
            sx={{
              transition: "transform 0.5s ease 0s",
              transform: filterParams.station
                ? "translateX(-100%)"
                : "translateX(0px)",
              height: "100%",
            }}
          >
            <Box width="100%" flexShrink={0}>
              <Chart
                options={{
                  theme: {
                    mode: appState.common.mode === "light" ? "light" : "dark",
                  },
                  states: {
                    hover: {
                      filter: {
                        type: "none",
                      },
                    },
                  },
                  chart: {
                    type: "bar",
                    height: 350,
                    stacked: true,
                    toolbar: {
                      show: true,
                    },
                    zoom: {
                      enabled: true,
                    },
                    events: {
                      dataPointSelection: (event, chartContext, config) => {
                        setFilterParams((one) => ({
                          ...one,
                          station:
                            filterParams.distributionBy === "area"
                              ? {
                                  areaId: data[config.dataPointIndex],
                                  status:
                                    LIST_STATUS_STATION[config.seriesIndex]
                                      ?.key,
                                }
                              : {
                                  riverBasinId: data[config.dataPointIndex],
                                  status:
                                    LIST_STATUS_STATION[config.seriesIndex]
                                      ?.key,
                                },
                        }));
                      },
                    },
                  },
                  xaxis: {
                    type: "category",
                    categories: data?.map((v) => v.name),
                  },
                  legend: {
                    position: "bottom",
                    offsetX: -10,
                    offsetY: 0,
                  },
                  fill: {
                    opacity: 1,
                  },
                }}
                series={series}
                type="bar"
                height={"100%"}
              />
            </Box>
            <Box width="100%" flexShrink={0} padding={2} overflow={"auto"}>
              <TableCustom
                dataSource={[80, 1, 2, 99, 0].reduce(
                  (value: any, current: number) => {
                    return [
                      ...value,
                      ...(constructionData?.items || [])?.filter(
                        (val) => val.status === current
                      ),
                    ];
                  },
                  []
                )}
                loading={isValidating}
                hideColumnIndex
                columns={[
                  {
                    title: (
                      <IconButton
                        onClick={() =>
                          setFilterParams((one) => ({
                            distributionBy: one.distributionBy,
                            station: undefined,
                          }))
                        }
                      >
                        <KeyboardBackspaceIcon />
                      </IconButton>
                    ),
                    align: "center",
                    dataIndex: "index",
                    minWidth: 70,
                  },

                  {
                    title: "construction.name",
                    dataIndex: "name",
                  },

                  {
                    title: "nreType",
                    dataIndex: "nreType.name",
                  },
                  {
                    title: "status",
                    dataIndex: "status",
                    render: (record) => {
                      const status = LIST_STATUS_STATION.find((status) =>
                        status.value.includes(record.status)
                      );
                      return (
                        <Typography color={status?.color} variant="caption">
                          {status?.label && (
                            <FormattedMessage id={status.label} />
                          )}
                        </Typography>
                      );
                    },
                  },
                ]}
              />
            </Box>
          </Box>
        </Box>
      </Paper>
    </Box>
  );
};

export default GroupChart;
