// Material UI
import { Box, Typography } from "@mui/material";
import React from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props {}
const MetadataTab = (props: Props) => {
  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();
  const { id } = useParams<{ id: string }>();

  const { data, isValidating, revalidate } = useSWR(
    id ? API_PATHS.licenseMetaData.index(id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onUpdateForm = React.useCallback(
    async (value: some) => {
      if (!id || !value.id) {
        return;
      }
      const json = await dispatch(
        fetchThunk(API_PATHS.licenseMetaData.update(id, value.id), "put", {
          ...value,
          constructionId: id,
        })
      );

      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      API_PATHS.licenseMetaData,
      dispatch,
      fetchThunk,
      id,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="licenseMetadata.list" />
        </Typography>
      </Box>
      <TableCustom
        dataSource={data}
        loading={isValidating}
        columns={[
          {
            title: "licenseMetadata.name",
            dataIndex: "constructionTypeMetadata.name",
          },
          {
            title: "licenseMetadata.value",
            dataIndex: "value",
            width: "50%",
            render: (record) => {
              return (
                <SchemaForm
                  hideSubmitButton
                  initialData={record}
                  onSubmit={onUpdateForm}
                  schema={{
                    fields: {
                      value: {
                        type: "text-field",
                        noHelperText: true,
                        onBlur: (e) => {
                          onUpdateForm({ ...record, value: e.target.value });
                        },
                      },
                    },
                  }}
                />
              );
            },
          },
        ]}
      />
    </>
  );
};

export default MetadataTab;
