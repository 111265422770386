import { InputAdornment } from "@mui/material";
import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";
import { SEPARATE_CODE } from "../constant";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
  indicators: some[];
  constructionTypeId: string;
  nreTypeId: string;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const {
    onSubmit,
    formData,
    onClose,
    indicators,
    constructionTypeId,
    nreTypeId,
  } = props;

  const hasList = indicators ? indicators.every((v) => !!v.indicators) : false;

  return (
    <>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
            methods: { setValue },
          }) => {
            return {
              construction: {
                hidden: formData?.id,
                type: "auto-complete",
                label: intl.formatMessage({ id: "construction.name" }),
                placeholder: intl.formatMessage({
                  id: "search",
                }),

                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.construction.index({
                        page: 0,
                        pageSize: 100,
                        name,
                        nreTypeId,
                        constructionTypeId: constructionTypeId,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                loadKey: [nreTypeId, constructionTypeId].join(","),
                onChange: () => {
                  setValue("monitoringLocation", null);
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              time: {
                type: "dateTimePicker",
                label: intl.formatMessage({ id: "periodicMonitoring.time" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              observer: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "periodicMonitoring.observer",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              attachment: {
                type: "uploadFile",
                label: intl.formatMessage({ id: "attachment" }),
                placeholder: intl.formatMessage({ id: "select" }),
                propsWrapper: { xs: 6 },
              },
              tram: {
                type: "auto-complete",
                options: indicators,
                multiple: true,
                limitTags: 3,
                label: intl.formatMessage({ id: "periodicMonitoring.tram" }),
                placeholder: intl.formatMessage({ id: "select" }),
                propsWrapper: { xs: 6 },
                isOptionEqualToValue: (option, value) =>
                  option.code === value.code,
                hidden: !hasList,
              },
              blank: {
                type: "raw",
                unregister: true,
                propsWrapper: { xs: 6 },
              },
              ...indicators?.reduce((value, current) => {
                if (current.indicators?.length) {
                  return {
                    ...value,
                    ...current.indicators?.reduce((v, c) => {
                      return {
                        ...v,
                        [`${current.code}_${c?.code}_v`]: {
                          type: "text-field",
                          inputType: "number",
                          label: c.name,
                          placeholder: intl.formatMessage({ id: "enter" }),
                          propsWrapper: { xs: 6 },
                          shouldUnregister: true,
                          InputProps: {
                            endAdornment: (
                              <InputAdornment position="end">
                                {current.unit}
                              </InputAdornment>
                            ),
                          },
                        },
                      };
                    }, {}),
                  };
                } else if (SEPARATE_CODE.includes(current.code)) {
                  return {
                    ...value,
                    [`${current.code}_v`]: {
                      type: "text-field",
                      inputType: "number",
                      label: intl.formatMessage({ id: "value" }),
                      placeholder: intl.formatMessage({ id: "enter" }),
                      propsWrapper: { xs: 6 },
                      shouldUnregister: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {current.unit}
                          </InputAdornment>
                        ),
                      },
                    },
                    [`${current.code}_s`]: {
                      type: "text-field",
                      label: intl.formatMessage({ id: "status" }),
                      placeholder: intl.formatMessage({ id: "enter" }),
                      propsWrapper: { xs: 6 },
                      shouldUnregister: true,
                    },
                  };
                } else {
                  return {
                    ...value,
                    [`${current.code}_v`]: {
                      type: "text-field",
                      inputType: "number",
                      label: current.name,
                      placeholder: intl.formatMessage({ id: "enter" }),
                      propsWrapper: { xs: 6 },
                      shouldUnregister: true,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment position="end">
                            {current.unit}
                          </InputAdornment>
                        ),
                      },
                    },
                  };
                }
              }, {}),
            };
          },
          ui: ({ valuesField }) => [
            {
              id: "default",
              fields: [
                "construction",
                "time",
                "attachment",
                "observer",
                "tram",
                ...(indicators || [])
                  ?.filter(
                    (item) =>
                      !item.indicators && !SEPARATE_CODE.includes(item.code)
                  )
                  ?.map((item) => {
                    return `${item.code}_v`;
                  })
                  .filter(Boolean),
              ],
            },
            ...(indicators || [])
              ?.filter(
                (item) => !item.indicators && SEPARATE_CODE.includes(item.code)
              )
              ?.map((item) => {
                return {
                  id: item.code,
                  title: item.name,
                  fields: [`${item.code}_v`, `${item.code}_s`],
                };
              }),
            ...(valuesField?.tram || [])?.map((item) => {
              if (item.indicators?.length > 0) {
                return {
                  id: item.code,
                  title: item.name,
                  fields: item.indicators.map(
                    (v) => `${item.code}_${v.code}_v`
                  ),
                };
              }
            }),
          ],
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={
        formData?.id ? "periodicMonitoring.update" : "periodicMonitoring.create"
      }
    >
      <SchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
