import { Typography } from "@mui/material";
import { Columns } from "../../../common/components/TableCustom/utils";
const month = [
  "I",
  "II",
  "III",
  "IV",
  "V",
  "VI",
  "VII",
  "VIII",
  "IX",
  "X",
  "XI",
  "XII",
];
export const report_1 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: <Typography variant="subtitle2">Lưu vực sông/Vùng</Typography>,

    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tổng số trạm quan trắc (trạm)</Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "reporting",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Loại trạm</Typography>,
    children: [
      {
        title: <Typography variant="body2">Khí tượng</Typography>,
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: (
          <Typography variant="body2">
            Thủy văn, thủy văn kết hợp tài nguyên nước
          </Typography>
        ),
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Tài nguyên nước độc lập</Typography>,
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Quan trắc nước dưới đất</Typography>,

        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_2 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: <Typography variant="subtitle2">Tên trạm</Typography>,

    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,

    rowSpan: 3,
    children: [
      {
        title: "1",
        rowSpan: 1,
        colSpan: 1,
        dataIndex: "lastPeriod",
      },
    ],
  },

  {
    title: <Typography variant="subtitle2">Tổng lượng mưa (mm)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Năm</Typography>,
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Mùa mưa</Typography>,
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Mùa khô</Typography>,
        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Quan trắc nước dưới đất</Typography>,

        children: [
          {
            title: <Typography variant="body2">Kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Kỳ báo cáo</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_3 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Tên trạm</Typography>,
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Lượng mưa tháng (mm)</Typography>,
    children: Array(12)
      .fill(0)
      .map((item, index) => ({ title: month[index] })),
  },
  {
    title: <Typography variant="subtitle2">Lượng mưa năm (mm)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Xn</Typography>,
      },
    ],
  },
] as Columns[];
export const report_4 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 3,
  },
  {
    title: <Typography variant="subtitle2">Lưu vực sông</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng dòng chảy năm (triệu m³)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "reporting",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng dòng chảy năm (triệu m³)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "reporting",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng dòng chảy năm (triệu m³)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Trung bình nhiều năm</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "reporting",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_5 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Tên trạm</Typography>,
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Thời kỳ quan trắc</Typography>,
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Lưu lượng (m³/s)</Typography>,
    children: Array(12)
      .fill(0)
      .map((item, index) => ({ title: month[index] })),
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng bình quân năm (m³/s)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Qn</Typography>,
      },
    ],
  },
] as Columns[];
export const report_6 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 3,
  },
  {
    title: <Typography variant="subtitle2">Lưu vực sông</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng hồ chứa tổng hợp (hồ)
      </Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: "1",
        rowSpan: 1,
        colSpan: 1,
        dataIndex: "lastPeriod",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng dòng chảy năm (triệu m³)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Toàn bộ (triệu m³)</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Hữu ích (triệu m³)</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Phòng lũ (triệu m³)</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "reporting",
          },
        ],
      },
      {
        title: (
          <Typography variant="body2">
            Tích được vào cuối mùa lũ, đầu mùa cạn (triệu m³)
          </Typography>
        ),
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_7 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Vùng/Khu vực</Typography>,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Vùng điều tra</Typography>,
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Diện tích được điều tra (km2)</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tầng chứa nước được điều tra</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tỷ lệ điều tra, đánh giá</Typography>
    ),
    children: [
      {
        title: <Typography variant="subtitle2">1</Typography>,
      },
    ],
  },
] as Columns[];
export const report_8 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Vùng/Tầng chứa nước</Typography>,

    rowSpan: 2,
  },
  {
    title: (
      <Typography variant="subtitle2">Số lượng giếng quan trắc</Typography>
    ),
    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Mực nước lớn nhất (m)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Mực nước trung bình (m)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Mực nước nhỏ nhất (m)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
] as Columns[];
export const report_9 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 2,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 2,
  },
  {
    title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng công trình khai thác nước mặt
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng công trình khai thác nước dưới đất
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
] as Columns[];
export const report_10 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Tổng số công trình</Typography>,

    rowSpan: 3,
    children: [
      {
        title: "1",
        rowSpan: 1,
        colSpan: 1,
        dataIndex: "lastPeriod",
      },
    ],
  },

  {
    title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
    children: [
      {
        title: <Typography variant="body2">Tưới</Typography>,
        children: [
          {
            title: <Typography variant="body2">Nguồn nước mặt</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Nguồn nước dưới đất</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Thủy điện</Typography>,
        rowSpan: 2,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "changeReport",
          },
        ],
      },
      {
        title: <Typography variant="body2">Mục đích khác</Typography>,
        children: [
          {
            title: <Typography variant="body2">Nguồn nước mặt</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Nguồn nước dưới đất</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_11 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
    rowSpan: 3,
    children: [
      {
        title: <Typography variant="body2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng công trình phân theo loại hình
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Khai thác nước mặt</Typography>,
        children: [
          {
            title: <Typography variant="body2">Hồ chứa</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Đập dâng</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
          {
            title: <Typography variant="body2">Cống</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
          {
            title: <Typography variant="body2">Trạm bơm</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
          {
            title: <Typography variant="body2">Khác</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "changeReport",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Khai thác nước dưới đất</Typography>,
        children: [
          {
            title: <Typography variant="body2">Giếng khoan</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Khác</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_12 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Tổng số công trình</Typography>,
    rowSpan: 3,
    children: [
      {
        title: <Typography variant="body2">1</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lượng nước khai thác, sử dụng đã được cấp phép phân theo mục đích khai
        thác và theo nguồn nước
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Tưới (m³/s)</Typography>,
        children: [
          {
            title: <Typography variant="body2">Nguồn nước mặt</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Nguồn nước dưới đất</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Thủy điện (MW)</Typography>,
        rowSpan: 2,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: (
          <Typography variant="body2">Mục đích khác (m³/ngày đêm)</Typography>
        ),
        children: [
          {
            title: <Typography variant="body2">Nguồn nước mặt</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Nguồn nước dưới đất</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "reporting",
              },
            ],
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_13 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 3,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Sông, suối/hồ chứa</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">&nbsp;</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Vị trí quan trắc</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">&nbsp;</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="body2">
        Kết quả phân tích Chỉ tiêu .........
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: <Typography variant="body2">Ghi chú</Typography>,
    rowSpan: 2,
    children: [
      {
        title: "1",
        rowSpan: 1,
        colSpan: 1,
        dataIndex: "lastPeriod",
      },
    ],
  },
] as Columns[];
export const report_14 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 3,
  },
  {
    title: (
      <Typography variant="subtitle2">Lưu vực sông/ Vùng/ Tỉnh</Typography>
    ),

    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Tầng chứa nước</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">&nbsp;</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Vị trí quan trắc</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">&nbsp;</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="body2">
        Kết quả phân tích Chỉ tiêu .........
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: <Typography variant="body2">Ghi chú</Typography>,
    rowSpan: 2,
    children: [
      {
        title: "1",
        rowSpan: 1,
        colSpan: 1,
        dataIndex: "lastPeriod",
      },
    ],
  },
] as Columns[];
export const report_15 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Cơ quan phát hành</Typography>,
  },

  {
    title: <Typography variant="subtitle2">Tổng số văn bản</Typography>,
    children: [
      {
        title: <Typography variant="body2">Kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Ghi chú</Typography>,
  },
] as Columns[];
export const report_16 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: <Typography variant="subtitle2">Loại giấy phép</Typography>,

    rowSpan: 3,
  },
  {
    title: (
      <Typography variant="subtitle2">Tổng số giấy phép đã cấp</Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số giấy phép cấp phân theo thẩm quyền
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Bộ TNMT cấp</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
          },
        ],
      },
      {
        title: <Typography variant="body2">Địa phương cấp</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_17 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Tỉnh</Typography>,
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số công trình đã phê duyệt tiền cấp quyền
      </Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số công trình đã phê duyệt tiền cấp quyền phân theo thẩm quyền
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Bộ TNMT phê duyệt</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
          },
        ],
      },
      {
        title: <Typography variant="body2">Địa phương phê duyệt</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số tiền cấp quyền đã phê duyệt (tỷ đồng)
      </Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
      },
    ],
  },
] as Columns[];
export const report_18 = [
  {
    title: "stt",
    dataIndex: "stt",
    rowSpan: 4,
  },
  {
    title: <Typography variant="subtitle2">Lưu vực sông/Tỉnh</Typography>,
    rowSpan: 3,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số sông, suối đã phê duyệt, công bố dòng chảy tối thiểu phân theo
        thẩm quyền
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Bộ TNMT</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
        ],
      },
      {
        title: <Typography variant="body2">Địa phương</Typography>,
        children: [
          {
            title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: (
              <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>
            ),
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
          {
            title: <Typography variant="body2">Thay đổi</Typography>,
            children: [
              {
                title: "1",
                rowSpan: 1,
                colSpan: 1,
                dataIndex: "lastPeriod",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số hồ chứa đã được phê duyệt, công bố dòng chảy tối thiểu ở hạ lưu
      </Typography>
    ),
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_19 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Tỉnh</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="subtitle2">Tổng</Typography>,
      },
    ],
  },

  {
    title: (
      <Typography variant="subtitle2">
        Số lượng các cuộc thanh tra, kiểm tra
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng đối tượng thanh tra, kiểm tra
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Số lượng tổ chức, cá nhân bị xử phạt
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng số tiền xử phạt vi phạm hành chính
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lũy kế đến kỳ trước</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Lũy kế đến kỳ báo cáo</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
      {
        title: <Typography variant="body2">Thay đổi</Typography>,
        children: [
          {
            title: "1",
            rowSpan: 1,
            colSpan: 1,
            dataIndex: "lastPeriod",
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_20 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Tên công trình</Typography>,
  },
  {
    title: (
      <Typography variant="subtitle2">
        Loại hình công trình (hồ, đập, cống, trạm bơm, giếng khoan, khác
      </Typography>
    ),
  },
  {
    title: (
      <Typography variant="subtitle2">
        Nguồn nước khai thác (sông, suối, hồ, đập, nước dưới đất)
      </Typography>
    ),
  },
  {
    title: <Typography variant="subtitle2">Vị trí</Typography>,
    rowSpan: 2,
    children: [
      {
        title: <Typography variant="body2">Xã</Typography>,
      },
      {
        title: <Typography variant="body2">Huyện</Typography>,
      },
      {
        title: <Typography variant="body2">Tỉnh</Typography>,
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Thông số cơ bản</Typography>,
    children: [
      {
        title: <Typography variant="body2">Hồ chứa, đập</Typography>,
        children: [
          {
            title: (
              <Typography variant="body2">
                Dung tích toàn bộ (triệu m³)
              </Typography>
            ),
          },
          {
            title: (
              <Typography variant="body2">
                Dung tích hữu ích (triệu m³)
              </Typography>
            ),
          },
          {
            title: <Typography variant="body2">Công suất (MW)</Typography>,
          },
        ],
      },
      {
        title: (
          <Typography variant="body2">Giếng khoan và loại hình khác</Typography>
        ),
        children: [
          {
            title: (
              <Typography variant="body2">
                Lưu lượng thiết kế (m³/ngày đêm)
              </Typography>
            ),
          },
          {
            title: (
              <Typography variant="body2">
                Lưu lượng thực tế (m³/ngày đêm)
              </Typography>
            ),
          },
        ],
      },
    ],
  },
] as Columns[];
export const report_21 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thời gian</Typography>,
    dataIndex: "time",
  },

  {
    title: <Typography variant="subtitle2">Lưu lượng đến hồ (m³/s)</Typography>,
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Tổng lưu lượng xả (m³/s)</Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">Dòng chảy tối thiểu (m³/s)</Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
] as Columns[];
export const report_22 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thời gian</Typography>,

    dataIndex: "time",
  },

  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng khai thác (m³/ngày đêm hoặc m³/s tùy loại hình)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng khai thác được cấp phép (m³/ngày đêm hoặc m³/s tùy loại hình)
      </Typography>
    ),
    width: "25%",
    dataIndex: "ll2",
  },
  {
    title: <Typography variant="subtitle2">Số ngày khai thác</Typography>,
    dataIndex: "date",
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng khai thác (Triệu m³)
      </Typography>
    ),
    dataIndex: "sum",
  },
] as Columns[];
export const report_23 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thời gian</Typography>,
    dataIndex: "time",
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng khai thác của giếng số…… (m³/ngày đêm)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng khai thác được cấp phép (m³/ngày đêm)
      </Typography>
    ),
    dataIndex: "time",
  },

  {
    title: (
      <Typography variant="subtitle2">
        Mực nước giếng khai thác số … (m)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
      },
      {
        title: (
          <Typography variant="body2">
            Chiều sâu mực nước động lớn nhất cho phép
          </Typography>
        ),
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Tổng lượng khai thác (Nghìn m³)
      </Typography>
    ),
  },
] as Columns[];
export const report_24 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thời gian</Typography>,

    dataIndex: "time",
  },

  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng xả nước thải (m³/ngày đêm)
      </Typography>
    ),
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
        dataIndex: "ll.max",
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
        dataIndex: "ll.min",
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
        dataIndex: "ll.average",
      },
    ],
  },
  {
    title: (
      <Typography variant="subtitle2">
        Lưu lượng xả thải được cấp phép (m³/ngày đêm)
      </Typography>
    ),
    width: "25%",
    dataIndex: "ll2",
  },
  {
    title: <Typography variant="subtitle2">Số ngày xả nước thải</Typography>,
    dataIndex: "date",
  },
  {
    title: (
      <Typography variant="subtitle2">Tổng lượng xả thải (Nghìn m³)</Typography>
    ),
    dataIndex: "sum",
  },
] as Columns[];
export const report_25 = [
  {
    title: "stt",
    dataIndex: "stt",
  },
  {
    title: <Typography variant="subtitle2">Thông số quan trắc</Typography>,
  },
  {
    title: <Typography variant="subtitle2">Đơn vị</Typography>,
  },

  {
    title: <Typography variant="subtitle2">Nồng độ</Typography>,
    children: [
      {
        title: <Typography variant="body2">Lớn nhất</Typography>,
      },
      {
        title: <Typography variant="body2">Nhỏ nhất</Typography>,
      },
      {
        title: <Typography variant="body2">Trung bình</Typography>,
      },
      {
        title: (
          <Typography variant="body2">
            Giới hạn quy định trong giấy phép
          </Typography>
        ),
      },
    ],
  },
  {
    title: <Typography variant="subtitle2">Ghi chú</Typography>,
  },
] as Columns[];
