import { Delete, Edit } from "@mui/icons-material";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onUpdate: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onUpdate } = props;

  const columns = [
    {
      title: "indicator.name",
      dataIndex: "indicator.name",
    },
    // {
    //   title: "indicator.removeDuplicate",
    //   dataIndex: "removeDuplicate",
    //   align: "center",
    // },
    {
      title: "indicator.removeNegative",
      align: "center",
      render: (record: some) => {
        return (
          <Checkbox
            color="secondary"
            checked={record.removeNegative}
            onChange={(e) => {
              onUpdate({
                ...record,
                removeNegative: e.target.checked,
              });
            }}
          />
        );
      },
    },
    {
      title: "indicator.removeZero",
      align: "center",
      render: (record: some) => {
        return (
          <Checkbox
            color="secondary"
            checked={record.removeZero}
            onChange={(e) => {
              onUpdate({
                ...record,
                removeZero: e.target.checked,
              });
            }}
          />
        );
      },
    },

    {
      title: "indicator.removeDeviceError",
      align: "center",
      render: (record: some) => {
        return (
          <Checkbox
            color="secondary"
            checked={record.removeDeviceError}
            onChange={(e) => {
              onUpdate({
                ...record,
                removeDeviceError: e.target.checked,
              });
            }}
          />
        );
      },
    },
    {
      title: "indicator.removeCalibrate",
      align: "center",
      render: (record: some) => {
        return (
          <Checkbox
            color="secondary"
            checked={record.removeCalibrate}
            onChange={(e) => {
              onUpdate({
                ...record,
                removeCalibrate: e.target.checked,
              });
            }}
          />
        );
      },
    },

    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="indicator.list" />
        </Typography>
        {/* <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => onCreateUpdateForm({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM> */}
      </Box>
      <TableCustom dataSource={data} loading={loading} columns={columns} />
    </>
  );
};
export default Table;
