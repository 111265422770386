// Material UI
import { Box, Paper, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import { some } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Component
import FilterConstruction from "../component/FilterConstruction";
import IndicatorBox from "../component/IndicatorBox";
import TableConstructionHistory from "../component/TableConstructionHistory";

interface Props {}

const ConstructionPeriodicMonitoringPage = (props: Props) => {
  const { constructionId } =
    useParams<{ nreTypeId: string; constructionId: string }>();
  const hookPagination = usePaginationHook();
  const { params, pagination } = hookPagination;
  const { dispatch, API_PATHS, fetchThunk } = useGeneralHook();
  const [filter, setFilter] = useState<some>({ columns: [] });

  const { data, isValidating } = useSWR(
    constructionId
      ? API_PATHS.periodic.constructions(constructionId, {
          ...params.date,
          ...pagination,
        })
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const { data: indicators = [], isValidating: loading } = useSWR(
    constructionId
      ? API_PATHS.periodic.indicatorsContruction(constructionId)
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  useEffect(() => {
    setFilter({ columns: indicators });
  }, [indicators]);

  return (
    <Box display="flex" flexDirection="column">
      <FilterConstruction {...hookPagination} />
      <Paper sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle1" style={{ flex: 1 }}>
            <FormattedMessage id="construction.list" />
          </Typography>
          <IndicatorBox
            data={indicators || []}
            filter={filter}
            setFilter={setFilter}
          />
        </Box>
        <Box marginTop={2}>
          <TableConstructionHistory
            data={data}
            loading={isValidating || loading}
            hookPagination={hookPagination}
            columnsFilter={filter.columns}
          />
        </Box>
      </Paper>
    </Box>
  );
};

export default ConstructionPeriodicMonitoringPage;
