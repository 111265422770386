// Material UI
import { Filter } from "@mui/icons-material";
import { Box } from "@mui/material";
import React, { useState } from "react";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
import ChangePassworDialog from "../component/ChangePassworDialog";
// Component
import FormDialog from "../component/FormDialog";
import Table from "../component/Table";

const UserPage = (props: any) => {
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [formData, setFormData] = useState<some | undefined>();
  const [changePass, setChangePass] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    API_PATHS.user.index({ page: params.page, size: params.pageSize }),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(API_PATHS.user.create, "post", value)
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  const onChangePass = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(API_PATHS.user.changePassword, "post", {
          newPassword: value.newPassword,
          userId: value.userId,
        })
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setChangePass(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.userId }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.user.delete, "post", { userId: value.userId })
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [confirmDialog, dispatch, intl, enqueueSnackbar, revalidate]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={data}
        loading={isValidating}
        hookPagination={hookPagination}
        onCreateUpdateForm={setFormData}
        onDelete={onDelete}
        setChangePass={setChangePass}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
      <ChangePassworDialog
        open={!!changePass}
        onClose={() => setChangePass(undefined)}
        formData={changePass}
        onSubmit={onChangePass}
      />
    </Box>
  );
};

export default UserPage;
