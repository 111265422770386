import { LoadingButton } from "@mui/lab";
import { InputAdornment, Typography } from "@mui/material";
import { Box } from "@mui/system";
import React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { development } from "../../../api/API_App";
import SchemaForm from "../../../common/SchemaForm";
import { LockIcon, PersonIcon } from "../../../svg";

export interface ILogin {
  username: string;
  password: string;
}

interface Props {
  onSubmit(data: ILogin): void;
  loading: boolean;
}

const LoginBox = (props: Props) => {
  const { onSubmit, loading } = props;
  const intl = useIntl();
  return (
    <Box style={{ padding: 16, paddingLeft: 100, flex: 1 }}>
      <Typography variant="h4" style={{ marginBottom: 24 }}>
        <FormattedMessage id="login" />
      </Typography>
      <SchemaForm
        initialData={
          development ? { username: "admin", password: "Admin@123" } : {}
        }
        formProps={{ style: { width: 300 } }}
        onSubmit={onSubmit}
        hideSubmitButton
        schema={{
          fields: ({ valuesField }) => ({
            username: {
              type: "text-field",
              label: intl.formatMessage({ id: "userName" }),
              placeholder: intl.formatMessage({ id: "userNameEnter" }),
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon className="svgStroke" />
                  </InputAdornment>
                ),
              },
              register: {
                required: true,
              },
            },
            password: {
              type: "text-field",
              label: intl.formatMessage({ id: "password" }),
              placeholder: intl.formatMessage({ id: "passwordEnter" }),
              inputType: "password",
              InputProps: {
                startAdornment: (
                  <InputAdornment position="start">
                    <LockIcon className="svgStroke" />
                  </InputAdornment>
                ),
              },
              register: {
                required: true,
              },
            },
            // captcha: {
            //   type: (params: FreeElementSchemaProps) => (
            //     <ReCAPTCHA
            //       sitekey="6LdkbTcdAAAAAMzrtOShbm1qpAaCt1YUZNvzq7n8"
            //       onChange={(value) => {
            //         params.field.onChange(value);
            //       }}
            //     />
            //   ),
            // },
            submit: {
              unregister: true,
              type: () => (
                <LoadingButton
                  variant="contained"
                  color="primary"
                  size="large"
                  type="submit"
                  loading={loading}
                  // disabled={!valuesField.captcha}
                >
                  <FormattedMessage id="login" />
                </LoadingButton>
              ),
            },
          }),
          changeDataBeforeSubmit: ({ captcha, ...values }) => {
            return { ...values };
          },
        }}
      />
      <Box position="absolute" bottom={24}>
        <Typography variant="body2" color="primary">
          <FormattedMessage id="policySecurity" />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          <FormattedMessage id="copyRight" />
        </Typography>
      </Box>
    </Box>
  );
};

export default LoginBox;
