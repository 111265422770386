import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose?: () => void;
  formData?: some;
  nreTypeCode?: string;
}

export const StationSchemaFormContent: React.FunctionComponent<{
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  formData?: some;
  nreTypeCode?: string;
}> = (props) => {
  const { onSubmit, formData } = props;
  return (
    <>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
            methods: { setValue },
          }) => {
            return {
              name: {
                type: "text-field",
                label: intl.formatMessage({ id: "station.name" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              code: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "station.code",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              area: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "area" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.area.index({
                        name,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              province: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "province" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.province.index({
                        name,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                noHelperText: true,
                register: {
                  required: true,
                },
                onChange: () => {
                  setValue("district", null);
                  setValue("commune", null);
                },
              },
              district: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "district" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadKey: valuesField.province,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.district.index({
                        name,
                        provinceId: valuesField.province?.id,
                        pageSize: 100,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                onChange: () => {
                  setValue("commune", null);
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
                noHelperText: true,
              },
              commune: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "commune" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadKey: valuesField.district,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.commune.index({
                        name,
                        districtId: valuesField.district?.id,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
                noHelperText: true,
              },
              address: {
                type: "text-field",
                label: intl.formatMessage({ id: "address" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              operationDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "station.operationDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              longitude: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "longitude" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              latitude: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "latitude" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },

              frequency: {
                type: "text-field",
                inputType: "number",
                label: intl.formatMessage({ id: "station.frequency" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        formData={formData}
        onReset
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const StationFormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <StationSchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default StationFormDialog;
