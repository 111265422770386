// Material UI
import { Add, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import moment from "moment";
import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import TableCustom from "../../../common/components/TableCustom";
import TagPM from "../../../common/components/TagPM";
import { DATE_FORMAT, some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import StationFormDialog from "./StationFormDialog";

interface Props {
  constructionData: some;
}
const StationTab = (props: Props) => {
  const {
    dispatch,
    enqueueSnackbar,
    confirmDialog,
    API_PATHS,
    fetchThunk,
    intl,
  } = useGeneralHook();
  const { id } = useParams<{ id: string }>();
  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    id
      ? API_PATHS.station.index({
          constructionId: id,
        })
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(API_PATHS.station.update(value.id), "put", {
              ...value,
              constructionId: id,
            })
          )
        : await dispatch(
            fetchThunk(API_PATHS.station.create, "post", {
              ...value,
              constructionId: id,
            })
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [API_PATHS.station, dispatch, fetchThunk, id, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(API_PATHS.station.update(value.id), "delete")
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      API_PATHS.station,
      confirmDialog,
      dispatch,
      fetchThunk,
      intl,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="station.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => setFormData({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustom
        dataSource={data}
        loading={isValidating}
        columns={[
          {
            title: "station.code",
            dataIndex: "code",
          },
          {
            title: "station.name",
            dataIndex: "name",
          },
          {
            title: "area",
            dataIndex: "area.name",
          },
          {
            title: "district",
            dataIndex: "district.name",
          },
          {
            title: "commune",
            dataIndex: "commune.name",
          },
          {
            title: "address",
            dataIndex: "address",
          },
          {
            title: "latitude",
            dataIndex: "latitude",
          },
          {
            title: "longitude",
            dataIndex: "longitude",
          },
          {
            title: "station.operationDate",
            render: (record: some) => {
              return (
                record.operationDate &&
                moment(record.operationDate).format(DATE_FORMAT)
              );
            },
          },

          {
            title: "action",
            align: "center",
            fixed: "right",
            width: 100,
            // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
            render: (record: some, index: number) => (
              <>
                <IconButton
                  style={{ marginRight: 8 }}
                  size="small"
                  onClick={() => {
                    setFormData(record);
                  }}
                >
                  <Edit />
                </IconButton>
                <TagPM>
                  <IconButton
                    onClick={() => {
                      onDelete(record);
                    }}
                    size="small"
                  >
                    <Delete />
                  </IconButton>
                </TagPM>
              </>
            ),
          },
        ]}
      />
      <StationFormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </>
  );
};

export default StationTab;
