import { Add, Check, Delete, Edit } from "@mui/icons-material";
import { Box, Button, IconButton, Typography } from "@mui/material";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import TagPM from "../../../common/components/TagPM";
import { some } from "../../../common/constants";

interface Props {
  data?: some[];
  loading: boolean;
  onCreateUpdateForm: (value: some) => void;
  onUpdate: (value: some) => void;
  onDelete: (value: some) => void;
}
const Table = (props: Props) => {
  const { data, loading, onCreateUpdateForm, onDelete, onUpdate } = props;

  const columns = [
    {
      title: "indicator.code",
      dataIndex: "indicator.code",
    },
    {
      title: "indicator.name",
      dataIndex: "indicator.name",
    },
    {
      title: "indicator.unit",
      dataIndex: "indicator.unit",
      align: "center",
    },
    {
      title: "indicator.indicatorSource",
      dataIndex: "indicatorSource",
      align: "center",
    },
    {
      title: "indicator.factor",
      dataIndex: "factor",
      align: "center",
    },
    {
      title: "indicator.obsdataFrequency",
      align: "center",
      render: (record) => {
        return record?.typeFrequency === "obsdata" ? (
          <Check color="success" />
        ) : (
          ""
        );
      },
    },
    {
      title: "indicator.predataFrequency",
      align: "center",
      render: (record) => {
        return record?.typeFrequency === "predata" ? (
          <Check color="success" />
        ) : (
          ""
        );
      },
    },
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 100,
      // hidden: !checkPermission([["INDICATOR_EDIT"], ["INDICATOR_DELETE"]]),
      render: (record: some, index: number) => (
        <>
          <TagPM>
            <IconButton
              style={{ marginRight: 8 }}
              size="small"
              onClick={() => {
                onCreateUpdateForm(record);
              }}
            >
              <Edit />
            </IconButton>
          </TagPM>
          <TagPM>
            <IconButton
              onClick={() => {
                onDelete(record);
              }}
              size="small"
            >
              <Delete />
            </IconButton>
          </TagPM>
        </>
      ),
    },
  ] as Columns[];

  return (
    <>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        marginBottom={2}
      >
        <Typography variant="h6">
          <FormattedMessage id="indicator.list" />
        </Typography>
        <TagPM>
          <Button
            startIcon={<Add />}
            color="primary"
            onClick={() => onCreateUpdateForm({})}
          >
            <FormattedMessage id="create" />
          </Button>
        </TagPM>
      </Box>
      <TableCustom dataSource={data} loading={loading} columns={columns} />
    </>
  );
};
export default Table;
