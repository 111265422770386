/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import { Box, Paper, Theme, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import TableCustom from "../../../../common/components/TableCustom";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { DisconnectCircle } from "../../../../svg";

const useStyles = makeStyles((theme: Theme) => ({
  table: {
    "@global": {
      td: {
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "inherit",
      },
      th: {
        backgroundColor:
          theme.palette.mode === "light" ? theme.palette.grey[100] : "inherit",
      },
    },
  },
}));

interface Props {}
const CompareBox = (props: Props) => {
  const styles = useStyles();
  const { dispatch, API_PATHS } = useGeneralHook();

  const { data: monthData } = useSWR(
    API_PATHS.dashboard.compareData({ timeRangeType: "month" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );
  const { data: yearData } = useSWR(
    API_PATHS.dashboard.compareData({ timeRangeType: "year" }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    }
  );

  const columns = [
    {
      title: "time",
      width: "15%",
      dataIndex: "name",
    },
    {
      title: (
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormattedMessage id={"dashboard.obsdataRatio"} />
        </Box>
      ),
      render: (record) => (
        <Box textAlign="center">
          <Typography variant="h6">{record.obsReceivePercent}%</Typography>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="data" values={{ num: record.obsTotal }} />
          </Typography>
        </Box>
      ),
    },
    {
      title: (
        <Box display="flex" alignItems="center" justifyContent="center">
          <FormattedMessage id={"dashboard.predataRatio"} />
        </Box>
      ),
      render: (record) => (
        <Box textAlign="center">
          <Typography variant="h6">{record.preReceivePercent}%</Typography>
          <Typography variant="body2" color="textSecondary">
            <FormattedMessage id="data" values={{ num: record.preTotal }} />
          </Typography>
        </Box>
      ),
    },
  ];
  return (
    <Paper
      sx={{ p: 2, height: "100%", display: "flex", flexDirection: "column" }}
    >
      <Typography variant="subtitle1" sx={{ marginBottom: 2 }}>
        <FormattedMessage id="dashboard.dataDevice" />
      </Typography>
      <Box display="flex" gap={2} flex={1}>
        <TableCustom
          boxProps={{ style: { flex: 1 } }}
          tableProps={{
            style: { backgroundColor: grey[600], height: "100%" },
            className: styles.table,
          }}
          columns={columns}
          dataSource={monthData}
          hideColumnIndex
        />
        <TableCustom
          boxProps={{ style: { flex: 1 } }}
          tableProps={{
            style: { backgroundColor: grey[600], height: "100%" },
            className: styles.table,
          }}
          columns={columns}
          dataSource={yearData}
          hideColumnIndex
        />
      </Box>
    </Paper>
  );
};

export default CompareBox;
