import { AnyAction } from "redux";
import { ThunkAction } from "redux-thunk";
import { ActionType, createCustomAction, getType } from "typesafe-actions";
import { API_PATHS } from "../../api/API_App";
import { some, SUCCESS_CODE } from "../../common/constants";
import { fetchThunk } from "../../common/reducer/thunk";
import { AppState } from "../../redux/reducer";

export interface NreTypeState {
  data?: some[];
}

export const setDataNRE = createCustomAction(
  "nre/setDataNRE",
  (val: some[]) => ({ val })
);

export function loadNRETypeData(): ThunkAction<
  Promise<void>,
  AppState,
  null,
  AnyAction
> {
  return async (dispatch) => {
    try {
      const json = await dispatch(
        fetchThunk(API_PATHS.nreType.index({ page: 0, pageSize: 10000 }), "get")
      );
      if (json.code === SUCCESS_CODE) {
        dispatch(setDataNRE(json?.data?.items || []));
      }
    } catch (e) {}
  };
}

const actions = {
  setDataNRE,
};

type Action = ActionType<typeof actions>;

export default function reducer(
  state: NreTypeState = {
    data: [],
  },
  action: Action
): NreTypeState {
  switch (action.type) {
    case getType(setDataNRE):
      return { ...state, data: action.val };
    default:
      return state;
  }
}
