// Material UI
import { Add } from "@mui/icons-material";
import {
  Box,
  Button,
  Collapse,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { FormattedMessage } from "react-intl";
import { useParams } from "react-router";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import FormDialog from "../component/FormDialog";
import IndicatorBox from "../component/IndicatorBox";
import Table from "../component/Table";
import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import UploadDialog from "./UploadDialog";

interface Props {
  constructionType: some;
  filterParams: some;
}

const MainContent = (props: Props) => {
  const { constructionType, filterParams } = props;
  const { nreTypeId = "" } = useParams<{ nreTypeId: string }>();
  const { dispatch, enqueueSnackbar } = useGeneralHook();
  const hookPagination = usePaginationHook();
  const { params } = hookPagination;
  const [formData, setFormData] = useState<some | undefined>();
  const [formDataUpload, setFormDataUpload] = useState<some | undefined>();
  const [filter, setFilter] = React.useState<some>({ columns: [] });
  const [open, setOpen] = React.useState(false);

  const { data, revalidate, isValidating } = useSWR(
    API_PATHS.periodic.index(nreTypeId, {
      ...params,
      constructionTypeId: constructionType?.id,
      constructionId: filterParams?.constructionId?.id,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const { data: indicators = [], isValidating: loading } = useSWR(
    constructionType?.id
      ? API_PATHS.periodic.indicators(constructionType?.id)
      : null,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.periodic.update(nreTypeId, value.id),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(API_PATHS.periodic.create(nreTypeId), "post", value)
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, nreTypeId, revalidate]
  );

  const onUpload = React.useCallback(
    async (files: File[], value: some) => {
      if (!value?.construction?.id) {
        return;
      }
      const formData = new FormData();
      formData.append("file", files[0]);
      const json = await dispatch(
        fetchThunk(
          API_PATHS.periodic.uploadSampleFile(value?.construction?.id),
          "post",
          formData,
          "multipart/form-data"
        )
      );

      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormDataUpload(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [dispatch, enqueueSnackbar, revalidate]
  );

  useEffect(() => {
    setFilter({ columns: indicators });
  }, [indicators]);

  React.useEffect(() => {
    if (data?.items?.length > 0) {
      setOpen(true);
    }
  }, [data]);

  return (
    <Box display="flex" flexDirection="column">
      <Paper sx={{ p: 2 }}>
        <Box display="flex" alignItems="center" gap={1}>
          <Typography variant="subtitle1" style={{ flex: 1 }}>
            {constructionType.name}
          </Typography>
          <IndicatorBox
            data={indicators || []}
            filter={filter}
            setFilter={setFilter}
          />
          <Button
            sx={{ flexShrink: 0 }}
            startIcon={<Add />}
            color="primary"
            onClick={() => setFormData({})}
          >
            <FormattedMessage id="periodicMonitoring.create" />
          </Button>
          <IconButton
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <KeyboardArrowDownRoundedIcon
              color="primary"
              style={{
                transform: `rotate(${open ? 0 : 180}deg)`,
                transition: "0.3s all",
              }}
            />
          </IconButton>
        </Box>
        {!(isValidating || loading) && (
          <Collapse in={open} unmountOnExit>
            <Box marginTop={2}>
              <Table
                data={data}
                loading={isValidating || loading}
                hookPagination={hookPagination}
                onCreateUpdateForm={setFormData}
                setFormDataUpload={setFormDataUpload}
                constructionTypeId={constructionType?.id}
                columnsFilter={filter.columns}
                indicators={indicators}
              />
            </Box>
          </Collapse>
        )}
      </Paper>
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
        indicators={indicators}
        constructionTypeId={constructionType?.id}
        nreTypeId={nreTypeId}
      />
      <UploadDialog
        open={!!formDataUpload}
        formData={formDataUpload}
        onClose={() => {
          setFormData(undefined);
        }}
        onSubmit={onUpload}
      />
    </Box>
  );
};

export default MainContent;
