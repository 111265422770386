// Material UI
import { Box } from "@mui/material";
import React, { useState } from "react";
// API
import useSWR from "swr";
import { API_PATHS } from "../../../api/API_App";
import { some, SUCCESS_CODE } from "../../../common/constants";
// Hooks
import useGeneralHook from "../../../common/hook/useGeneralHook";
// Redux
import { fetchThunk } from "../../../common/reducer/thunk";
// Component
import FormDialog from "./FormDialog";
import Table from "./Table";

interface Props {
  constructionData: some;
}
const SensorTab = (props: Props) => {
  const { constructionData } = props;
  const { dispatch, enqueueSnackbar, confirmDialog, intl } = useGeneralHook();

  const [formData, setFormData] = useState<some | undefined>();

  const { data, isValidating, revalidate } = useSWR(
    constructionData?.id
      ? API_PATHS.constructionSensor.index(constructionData?.id)
      : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const onCreateUpdateForm = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      if (!constructionData?.id) {
        return;
      }
      setLoading(true);
      const json = value.id
        ? await dispatch(
            fetchThunk(
              API_PATHS.constructionSensor.update(
                constructionData?.id,
                value.id
              ),
              "put",
              value
            )
          )
        : await dispatch(
            fetchThunk(
              API_PATHS.constructionSensor.index(constructionData?.id),
              "post",
              value
            )
          );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
        setFormData(undefined);
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [constructionData?.id, dispatch, enqueueSnackbar, revalidate]
  );

  const onDelete = React.useCallback(
    async (value: some) => {
      if (!constructionData?.id) {
        return;
      }
      const confirm = await confirmDialog.promptConfirmation({
        warning: true,
        title: intl.formatMessage({ id: "confirm" }),
        content: intl.formatMessage(
          { id: "confirmDelete" },
          { name: value.name }
        ),
      });
      if (confirm) {
        const json = await dispatch(
          fetchThunk(
            API_PATHS.constructionSensor.update(constructionData?.id, value.id),
            "delete"
          )
        );

        if (json.code === SUCCESS_CODE) {
          revalidate();
          setFormData(undefined);
        }
        enqueueSnackbar({
          message: json.msg,
          requestId: json.requestId,
          variant: json.code,
        });
      }
      confirmDialog.close();
    },
    [
      confirmDialog,
      dispatch,
      constructionData?.id,
      intl,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <Box display="flex" flexDirection="column">
      <Table
        data={data}
        loading={isValidating}
        onCreateUpdateForm={setFormData}
        onDelete={onDelete}
      />
      <FormDialog
        open={!!formData}
        onClose={() => setFormData(undefined)}
        formData={formData}
        onSubmit={onCreateUpdateForm}
      />
    </Box>
  );
};

export default SensorTab;
