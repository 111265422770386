/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import { Box, Paper, Typography } from "@mui/material";
import "leaflet/dist/leaflet.css";
import { get } from "lodash";
import { FormattedMessage, FormattedNumber } from "react-intl";
import useSWR from "swr";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { LIST_STATUS_STATION } from "../../constants";

interface Props {}
const ConstructionStatusBox = (props: Props) => {
  const { dispatch, API_PATHS } = useGeneralHook();

  const { data } = useSWR(
    API_PATHS.dashboard.constructionStatistic,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  return (
    <Box height={"100%"} display={"flex"} flexDirection="column">
      <Typography variant="h6" marginBottom={2}>
        <FormattedMessage id="dashboard.constructionTotal" />
        &nbsp;
        {data?.total !== undefined && (
          <FormattedNumber value={data?.total} maximumFractionDigits={2} />
        )}
      </Typography>
      <Box display={"flex"} flexDirection="column" gap={2} flex={1}>
        {LIST_STATUS_STATION.map((item: some, index: number) => {
          return (
            <Paper
              key={index}
              variant="outlined"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                padding: 3,
                gap: 2,
                position: "relative",
                backgroundColor: item.backgroundColor,
                color: "common.black",
                minWidth: 120,
                flex: 1,
              }}
            >
              {item.icon}
              <Box>
                <Typography variant="h6" color="inherit">
                  <FormattedNumber value={get(data, `${item.key}.total`, 0)} />
                </Typography>
                <Typography variant="body2" color="inherit">
                  <FormattedMessage id={item.label} />
                </Typography>
                {/* <LinkPM
                      to={{
                        pathname: `/${ROUTES.monitorOnline.index}/${tab}`,
                        search: stringifyUrl({
                          status: item.value,
                        }),
                      }}
                    >
                      <ButtonBase
                        sx={{
                          p: 1,
                          position: "absolute",
                          right: 0,
                          bottom: 0,
                          borderRadius: "50px 0px 0px 0px",
                          padding: 1,
                          bgcolor: "white",
                        }}
                      >
                        <ArrowRightAltIcon
                          sx={{ marginTop: 1, marginLeft: 1 }}
                        />
                      </ButtonBase>
                    </LinkPM> */}
              </Box>
            </Paper>
          );
        })}
      </Box>
    </Box>
  );
};

export default ConstructionStatusBox;
