import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import HomeRoundedIcon from "@mui/icons-material/HomeRounded";
import { Box, Portal, Typography } from "@mui/material";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Outlet, useLocation, useRoutes } from "react-router";
import { NavLink, useResolvedPath } from "react-router-dom";
import { IRouteObject } from "./utils";
interface Props {
  listRouter: IRouteObject[];
}

function BreadcrumbsItem(props) {
  const { title, disable } = props;
  const location = useLocation();
  const resolvedLocation = useResolvedPath("");
  const isActive = location.pathname === resolvedLocation.pathname;

  return (
    <>
      {isActive ? (
        <>
          <Portal container={document.getElementById("breadcrumbTitle")}>
            <Typography variant="h5">
              {typeof title === "string" && title ? (
                <FormattedMessage id={title} />
              ) : (
                title
              )}
            </Typography>
          </Portal>
          <Typography variant="subtitle2">
            {typeof title === "string" && title ? (
              <FormattedMessage id={title} />
            ) : (
              title
            )}
          </Typography>
        </>
      ) : (
        <>
          {disable ? (
            <Typography variant="subtitle2" color="textSecondary">
              {typeof title === "string" && title ? (
                <FormattedMessage id={title} />
              ) : (
                title
              )}
            </Typography>
          ) : (
            <NavLink to={resolvedLocation.pathname}>
              <Typography variant="subtitle2" color="textSecondary">
                {typeof title === "string" && title ? (
                  <FormattedMessage id={title} />
                ) : (
                  title
                )}
              </Typography>
            </NavLink>
          )}
          &nbsp;
          <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
          &nbsp;
        </>
      )}
      <Outlet />
    </>
  );
}

const DefaultBreadcrumb: React.FunctionComponent<Props> = (props) => {
  const { listRouter } = props;
  const mappedRoutes = (list: IRouteObject[], rank: number) => {
    return list
      .filter((route) => !route.staticRoute)
      .map((one) => ({
        ...one,
        element: !one.index && (
          <BreadcrumbsItem
            title={one.title}
            disable={
              (one?.children && one?.children?.length === 0) ||
              !one.children?.find((v) => v.index) ||
              (!rank && one?.children.length > 0)
            }
          />
        ),
        children:
          one.children &&
          one.children?.length > 0 &&
          mappedRoutes(one.children, rank + 1),
      }));
  };

  const breadcrumbsElements = useRoutes(mappedRoutes(listRouter, 0));

  return (
    <>
      <Box display="flex" alignItems="center" paddingTop={2} color={"grey.500"}>
        <>
          <HomeRoundedIcon /> &nbsp;
          <ArrowForwardIosRoundedIcon style={{ fontSize: 14 }} />
          &nbsp;
          {breadcrumbsElements}
        </>
      </Box>
      <Box id="breadcrumbTitle" paddingTop={1} paddingBottom={1} />
    </>
  );
};

export default DefaultBreadcrumb;
