import { Box, Checkbox, Typography } from "@mui/material";
import { Controller, useFieldArray, useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { some } from "../../../common/constants";
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import CheckBoxElement from "../../../common/SchemaForm/element/checkbox/CheckBoxElement";
import { mergeFieldName } from "../../../common/SchemaForm/utils";
import { capitalizeFirstLetter } from "../../../common/utils";

const colorStatus = {
  uncensored: "#FACF39",
  waiting_for_approval: "#DD1A0C",
  rejected: "#9C27B0",
  approved: "#00AB55",
};
interface Props {
  loading: boolean;
  hookPagination: HookPaginationProps;
  indicators: some;
  filter: some;
  rawData?: some;
  isBoss?: boolean;
}

const Table = (props: Props) => {
  const { isBoss, rawData, hookPagination, loading, indicators, filter } =
    props;
  const methods = useFormContext();
  const { pagination, onPageChange, onRowsPerPageChange } = hookPagination;
  const { control, getValues, watch } = methods;
  const { update } = useFieldArray({
    control,
    name: "items",
  });
  const fields = watch("items");

  const columns = [
    {
      title: "station.name",
      fixed: "left",
      minWidth: 160,
      width: 160,
      align: "center",
      dataIndex: "stationName",
    },
    {
      title: "time",
      fixed: "left",
      minWidth: 160,
      width: 160,
      align: "center",
      dataIndex: "time",
    },
    {
      title: "status",
      fixed: "left",
      minWidth: 140,
      width: 140,
      hidden: filter.dataType === "kdtc",
      render: (record) => {
        return (
          record.status && (
            <Typography
              variant="caption"
              style={{
                color: colorStatus[record.status],
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
              component="div"
            >
              <Box
                style={{
                  background: colorStatus[record.status],
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                }}
              />
              <FormattedMessage id={record.status} />
            </Typography>
          )
        );
      },
    },
    ...indicators?.columns?.map((column) => {
      if (column.indicators) {
        return {
          title: (
            <Box>
              <Typography
                variant="caption"
                fontWeight="bold"
                style={{ wordBreak: "break-word" }}
                component="div"
              >
                {capitalizeFirstLetter(column.name)}
              </Typography>
            </Box>
          ),
          align: "center",
          children: column.indicators?.map((indicator) => {
            return {
              title: (
                <Box>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="span"
                  >
                    {capitalizeFirstLetter(indicator.name)}
                  </Typography>
                  {indicator.unit && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      &nbsp;({indicator.unit})
                    </Typography>
                  )}
                </Box>
              ),
              align: "center",
              render: (record: some, index) => {
                const value = record?.[`${indicator.dataColumnCode}_v`];
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    gap={1}
                    key={record.id}
                  >
                    <Box
                      sx={{
                        padding: "2px 8px",
                        bgcolor: "#00AB55",
                        color: "white",
                        borderRadius: 1,
                        minWidth: 48,
                        minHeight: 24,
                      }}
                    >
                      {value}
                    </Box>
                    <Controller
                      name={mergeFieldName({
                        name: `${indicator.dataColumnCode}_is_removed`,
                        index,
                        parent: "items",
                      })}
                      defaultValue={false}
                      control={control}
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <CheckBoxElement
                          value={value || false}
                          onChange={(...params) => {
                            onChange(...params);
                          }}
                          fromControlProps={{
                            style: { margin: 0 },
                          }}
                          error={!!error}
                          disabled={
                            isBoss
                              ? !rawData?.items?.[index]?.[
                                  `${indicator.dataColumnCode}_is_removed`
                                ]
                              : false
                          }
                        />
                      )}
                    />
                  </Box>
                );
              },
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography
              variant="caption"
              fontWeight="bold"
              style={{ wordBreak: "break-word" }}
              component="div"
            >
              {capitalizeFirstLetter(column.name)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {column.unit}
            </Typography>
          </Box>
        ),
        align: "center",
        minWidth: 140,
        render: (record: some, index) => {
          const value = record?.[`${column.dataColumnCode}_v`];
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              gap={1}
              key={record.id}
            >
              <Box
                sx={{
                  padding: "2px 8px",
                  bgcolor: "#00AB55",
                  color: "white",
                  borderRadius: 1,
                  minWidth: 48,
                  minHeight: 24,
                }}
              >
                {value}
              </Box>
              <Controller
                name={mergeFieldName({
                  name: `${column.dataColumnCode}_is_removed`,
                  index,
                  parent: "items",
                })}
                defaultValue={false}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CheckBoxElement
                    value={value || false}
                    onChange={(...params) => {
                      onChange(...params);
                    }}
                    fromControlProps={{
                      style: { margin: 0 },
                    }}
                    error={!!error}
                    disabled={
                      isBoss
                        ? !rawData?.items?.[index]?.[
                            `${column.dataColumnCode}_is_removed`
                          ]
                        : false
                    }
                  />
                )}
              />
            </Box>
          );
        },
      };
    }),
    isBoss
      ? {
          title: "qa_qc.sendData",
          fixed: "right",
          minWidth: 80,
          width: 80,
          align: "center",
          render: (record, index) => {
            return (
              <Controller
                name={mergeFieldName({
                  name: "isChecked",
                  index,
                  parent: "items",
                })}
                defaultValue={false}
                control={control}
                render={({
                  field: { onChange, value },
                  fieldState: { error },
                }) => (
                  <CheckBoxElement
                    value={value || false}
                    onChange={(...params) => {
                      onChange(...params);
                    }}
                    fromControlProps={{
                      style: { margin: 0 },
                    }}
                    error={!!error}
                    disabled={false}
                  />
                )}
              />
            );
          },
        }
      : {
          title: "action",
          fixed: "right",
          minWidth: 80,
          width: 80,
          align: "center",
          render: (record, index) => {
            const mappedColumn = indicators?.columns
              ?.reduce((v, c) => {
                if (c.indicators) {
                  return [...v, ...c.indicators];
                }
                return [...v, c];
              }, [])
              .filter((v) => {
                if (isBoss) {
                  return (
                    rawData?.items?.[index]?.[
                      `${v.dataColumnCode}_is_removed`
                    ] === true
                  );
                }
                return true;
              });

            const checked =
              mappedColumn.length > 0
                ? mappedColumn?.every((col) => {
                    const val = getValues(
                      `items.${index}.${col.dataColumnCode}_is_removed`
                    );
                    return val === true;
                  })
                : false;

            return (
              <Checkbox
                color="secondary"
                checked={checked}
                disabled={!mappedColumn.length}
                onChange={(e) => {
                  update(
                    index,
                    mappedColumn.reduce((v, c) => {
                      return {
                        ...v,
                        [`${c.dataColumnCode}_is_removed`]: e.target.checked,
                      };
                    }, record)
                  );
                }}
              />
            );
          },
        },
  ] as Columns[];

  const columnsView = [
    {
      title: "station.name",
      fixed: "left",
      minWidth: 160,
      width: 160,
      align: "center",
      dataIndex: "stationName",
    },
    {
      title: "time",
      fixed: "left",
      minWidth: 160,
      width: 160,
      align: "center",
      dataIndex: "time",
    },
    {
      title: "status",
      fixed: "left",
      minWidth: 140,
      width: 140,
      hidden: filter.dataType === "kdtc",
      render: (record) => {
        return (
          record.status && (
            <Typography
              variant="caption"
              style={{
                color: colorStatus[record.status],
                display: "flex",
                alignItems: "center",
                gap: 8,
              }}
              component="div"
            >
              <Box
                style={{
                  background: colorStatus[record.status],
                  height: 8,
                  width: 8,
                  borderRadius: 8,
                }}
              />
              <FormattedMessage id={record.status} />
            </Typography>
          )
        );
      },
    },
    ...indicators?.columns?.map((column) => {
      if (column.indicators) {
        return {
          title: (
            <Box>
              <Typography
                variant="caption"
                fontWeight="bold"
                style={{ wordBreak: "break-word" }}
                component="div"
              >
                {capitalizeFirstLetter(column.name)}
              </Typography>
            </Box>
          ),
          align: "center",
          children: column.indicators?.map((indicator) => {
            return {
              title: (
                <Box>
                  <Typography
                    variant="caption"
                    fontWeight="bold"
                    component="span"
                  >
                    {capitalizeFirstLetter(indicator.name)}
                  </Typography>
                  {indicator.unit && (
                    <Typography
                      variant="caption"
                      color="textSecondary"
                      component="span"
                    >
                      &nbsp;({indicator.unit})
                    </Typography>
                  )}
                </Box>
              ),
              align: "center",
              render: (record: some, index) => {
                const value = record?.[`${indicator.dataColumnCode}_v`];
                return (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent={"center"}
                    gap={1}
                    key={record.id}
                  >
                    {value && (
                      <Box
                        sx={{
                          padding: "2px 8px",
                          bgcolor: "#00AB55",
                          color: "white",
                          borderRadius: 1,
                          minWidth: 48,
                          minHeight: 24,
                        }}
                      >
                        {value}
                      </Box>
                    )}
                  </Box>
                );
              },
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography
              variant="caption"
              fontWeight="bold"
              style={{ wordBreak: "break-word" }}
              component="div"
            >
              {capitalizeFirstLetter(column.name)}
            </Typography>
            <Typography variant="caption" color="textSecondary">
              {column.unit}
            </Typography>
          </Box>
        ),
        align: "center",
        minWidth: 140,
        render: (record: some, index) => {
          const value = record?.[`${column.dataColumnCode}_v`];
          return (
            <Box
              display="flex"
              alignItems="center"
              justifyContent={"center"}
              gap={1}
              key={record.id}
            >
              {value && (
                <Box
                  sx={{
                    padding: "2px 8px",
                    bgcolor: "#00AB55",
                    color: "white",
                    borderRadius: 1,
                    minWidth: 48,
                    minHeight: 24,
                  }}
                >
                  {value}
                </Box>
              )}
            </Box>
          );
        },
      };
    }),
  ] as Columns[];

  return (
    <TableCustom
      dataSource={fields}
      loading={loading}
      fixIndexColumn
      paginationProps={{
        count: methods.getValues("total"),
        page: pagination.page,
        rowsPerPage: pagination.pageSize,
        onPageChange: onPageChange,
        onRowsPerPageChange: onRowsPerPageChange,
        rowsPerPageOptions: [10, 25],
      }}
      columns={filter.dataType === "kdtc" ? columnsView : columns}
    />
  );
};
export default Table;
