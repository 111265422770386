import { Box, Paper, Typography } from "@mui/material";
import { useParams } from "react-router";
import TableCustom from "../../../common/components/TableCustom";
import { NoData } from "../../../svg";
import {
  report_1,
  report_10,
  report_11,
  report_12,
  report_13,
  report_14,
  report_15,
  report_16,
  report_17,
  report_18,
  report_19,
  report_2,
  report_20,
  report_21,
  report_22,
  report_23,
  report_24,
  report_25,
  report_3,
  report_4,
  report_5,
  report_6,
  report_7,
  report_8,
  report_9,
} from "./constants";

interface Props {}
const TableReport = (props: Props) => {
  const { id = "1" } = useParams<{ id: any }>();

  const columnsList = {
    1: {
      name: "Số lượng trạm quan trắc khí tượng, thủy văn, tài nguyên nước, nước dưới đất (1)",
      columns: report_1,
    },
    2: {
      name: "Tổng lượng mưa, phân phối lượng mưa trong năm (2)",
      columns: report_2,
    },
    3: {
      name: "Lượng mưa tháng, năm trong kỳ báo cáo (3)",
      columns: report_3,
    },
    4: {
      name: "Tổng lượng nước mặt trên các lưu vực sông (4)",
      columns: report_4,
    },
    5: {
      name: "Dòng chảy trung bình tháng, năm trong kỳ báo cáo (5)",
      columns: report_5,
    },
    6: {
      name: "Tổng hợp dung tích các hồ chứa lớn, quan trọng trên các lưu vực sông (6)",
      columns: report_6,
    },
    7: {
      name: "Diện tích đã được điều tra, đánh giá tài nguyên nước dưới đất (7)",
      columns: report_7,
    },
    8: {
      name: "Tổng hợp các đặc trưng mực nước dưới đất (8)",
      columns: report_8,
    },
    9: {
      name: "Số lượng công trình khai thác phân theo nguồn nước (9)",
      columns: report_9,
    },
    10: {
      name: "Số lượng công trình khai thác nước mặt, nước dưới đất phân theo mục đích sử dụng (10)",
      columns: report_10,
    },
    11: {
      name: "Số lượng công trình khai thác phân theo loại hình công trình khai thác (11)",
      columns: report_11,
    },
    12: {
      name: "Lượng nước khai thác, sử dụng (quy mô) đã được cấp giấy phép khai thác tài nguyên nước (12)",
      columns: report_12,
    },
    13: {
      name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước mặt (13)",
      columns: report_13,
    },
    14: {
      name: "Tổng hợp các đặc trưng của các chỉ tiêu cơ bản về chất lượng nước dưới đất (14)",
      columns: report_14,
    },
    15: {
      name: "Tổng hợp số lượng văn bản quy phạm pháp luật về tài nguyên nước đã ban hành (15)",
      columns: report_15,
    },
    16: {
      name: "Tổng hợp số lượng giấy phép tài nguyên nước đã được cấp (16)",
      columns: report_16,
    },
    17: {
      name: "Tổng hợp kết quả phê duyệt tiền cấp quyền khai thác tài nguyên nước (17)",
      columns: report_17,
    },
    18: {
      name: "Tổng hợp kết quả đánh giá, phê duyệt và công bố dòng chảy tối thiểu (18)",
      columns: report_18,
    },
    19: {
      name: "Tổng hợp kết quả thanh tra trong lĩnh vực tài nguyên nước (19)",
      columns: report_19,
    },
    20: {
      name: "Danh mục các công trình khai thác, sử dụng tài nguyên nước (20)",
      columns: report_20,
    },
    21: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình hồ chứa (21)",
      columns: report_21,
    },
    22: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước mặt đối với loại hình khai thác, sử dụng nước khác hồ chứa (22)",
      columns: report_22,
    },
    23: {
      name: "Tổng hợp tình hình khai thác, sử dụng nước nước dưới đất (23)",
      columns: report_23,
    },
    24: {
      name: "Tổng hợp tình hình xả nước thải (24)",
      columns: report_24,
    },
    25: {
      name: "Tổng hợp các đặc trưng về nồng độ các chất ô nhiễm theo từng thông số quy định trong giấy phép (25)",
      columns: report_25,
    },
  };

  if (!columnsList[id]) {
    return (
      <Box display="flex" justifyContent="center">
        <NoData />
      </Box>
    );
  }
  return (
    <>
      <Paper sx={{ p: 2 }}>
        {" "}
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          marginBottom={2}
        >
          <Typography variant="h6">{columnsList[id]?.name}</Typography>
        </Box>
        <TableCustom
          dataSource={[
            {
              stt: 1,
              time: "time",
              ll: { max: 10, min: 2, average: 5 },
              ll2: 200,
              date: 1200,
              sum: 12,
            },
          ]}
          columns={columnsList[id]?.columns}
          hideColumnIndex
        />
      </Paper>
    </>
  );
};
export default TableReport;
