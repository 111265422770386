import * as React from "react";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  formData?: some;
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open?: boolean;
  onClose?: () => void;
  constructionData?: some;
}
interface PropsContent {
  formData?: some;
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  onClose?: () => void;
  constructionData?: some;
}

export const LicenseSchemaFormContent: React.FunctionComponent<PropsContent> = (
  props
) => {
  const { onSubmit, formData, onClose, constructionData } = props;
  return (
    <>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, fetchThunk, API_PATHS },
            valuesField,
          }) => {
            return {
              code: {
                type: "text-field",
                label: intl.formatMessage({ id: "license.code" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              construction: {
                type: "auto-complete",
                hidden: !!constructionData,
                label: intl.formatMessage({ id: "construction" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.construction.index({
                        name,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              oldLicense: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "license.oldLicense" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadKey: valuesField.construction,
                loadOptions: async (code: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.license.index({
                        code,
                        constructionId: valuesField.construction?.id,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    code: v.code,
                  }));
                },
                getOptionLabel: (options) => options.code,
                propsWrapper: { xs: 6 },
              },
              licenseType: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licenseType" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.licenseType.index({
                        name,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              decisionDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.decisionDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              effectiveStartDate: {
                type: "datePicker",
                label: intl.formatMessage({
                  id: "license.effectiveStartDate",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                minDate: valuesField.decisionDate,
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              timeRange: {
                type: "text-field",
                label: intl.formatMessage({
                  id: "license.timeRange",
                }),
                placeholder: intl.formatMessage({ id: "enter" }),
                inputType: "number",
                register: {
                  required: true,
                  min: {
                    value: 0,
                    message: intl.formatMessage({
                      id: "license.timeRangeValid",
                    }),
                  },
                },
                propsWrapper: { xs: 6 },
              },
              licensingType: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licensingType" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                disableSearchByText: true,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.licensingType.index({
                        name,
                      })
                    )
                  );
                  return json.data;
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              licensingAuthority: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "licensingAuthority" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                disableSearchByText: true,
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.licensingAuthority.index({
                        name,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                  }));
                },
                register: {
                  required: true,
                },
                propsWrapper: { xs: 6 },
              },
              description: {
                type: "text-field",
                label: intl.formatMessage({ id: "description" }),
                placeholder: intl.formatMessage({ id: "enter" }),
                propsWrapper: { xs: 6 },
              },
              attachment: {
                type: "uploadFile",
                label: intl.formatMessage({ id: "license.file" }),
                propsWrapper: { xs: 6 },
              },
              applicableStatus: {
                type: "checkbox",
                label: intl.formatMessage({ id: "license.applicableStatus" }),
                propsWrapper: { xs: 6 },
              },
            };
          },
        }}
        hiddenField={constructionData}
        onSubmit={onSubmit}
        formData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </>
  );
};

const FormDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DialogCustomFullScreen
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      title={formData?.id ? "update" : "create"}
    >
      <LicenseSchemaFormContent {...props} />
    </DialogCustomFullScreen>
  );
};
export default FormDialog;
