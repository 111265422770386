// Material UI
import RefreshIcon from "@mui/icons-material/Refresh";
import * as React from "react";
// Hooks + Common
import { HookPaginationProps } from "../../../common/hook/usePaginationHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props extends HookPaginationProps {}
const Filter = (props: Props) => {
  const { filter, setParams, clearParams } = props;

  return (
    <SchemaForm
      formProps={{ style: { marginBottom: 16 } }}
      formData={filter}
      onSubmit={setParams}
      hideSubmitButton
      schema={{
        fields: ({
          formProps: { intl, dispatch, API_PATHS, fetchThunk },
          valuesField,
        }) => ({
          constructionId: {
            type: "auto-complete",
            label: intl.formatMessage({ id: "construction.name" }),
            placeholder: intl.formatMessage({
              id: "select",
            }),

            loadOptions: async (name: string) => {
              const json = await dispatch(
                fetchThunk(
                  API_PATHS.construction.index({
                    page: 0,
                    pageSize: 25,
                    name,
                  })
                )
              );
              return json.data?.items?.map((v) => ({ id: v.id, name: v.name }));
            },
            register: {
              required: true,
            },
            propsWrapper: { xs: 3 },
          },
          date: {
            type: "dateRange",
            propsWrapper: { xs: 4 },
            register: {
              required: true,
            },
          },
          dataType: {
            type: "select",
            options: [
              { id: "kdtd", name: "origin" },
              { id: "kdtc", name: "adjust" },
            ],
            disableClearBtn: true,
            label: intl.formatMessage({ id: "dataType" }),
            placeholder: intl.formatMessage({ id: "select" }),
            propsWrapper: { xs: 2 },
          },
          status: {
            type: "select",
            hidden: valuesField.dataType === "kdtc",
            options: [
              { id: "uncensored", name: "uncensored" },
              { id: "waiting_for_approval", name: "waiting_for_approval" },
              { id: "approved", name: "approved" },
              { id: "rejected", name: "rejected" },
            ],
            hasAllOptions: true,
            disableClearBtn: true,
            label: intl.formatMessage({ id: "status" }),
            placeholder: intl.formatMessage({ id: "select" }),
            propsWrapper: { xs: 2 },
          },
          search: {
            type: "submitButton",
            fullWidth: true,
            label: intl.formatMessage({ id: "search" }),
            propsWrapper: { xs: "auto", marginTop: 2.5 },
          },
          reset: {
            type: "button",
            variant: "text",
            fullWidth: true,
            label: <RefreshIcon />,
            style: { padding: 0, minWidth: "unset", borderRadius: "50%" },
            propsWrapper: { xs: "auto", marginTop: 3 },
            onClick: () => {
              clearParams();
            },
          },
        }),
      }}
    />
  );
};
export default Filter;
