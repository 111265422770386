// Material UI
// Hooks + Common
import { Box, Checkbox, Divider, Paper, Typography } from "@mui/material";
import { isEqual, uniqBy } from "lodash";
import { useCallback, useMemo } from "react";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { FreeElementSchemaProps } from "../../../../common/SchemaForm/utils";
import MenuTreeSelect from "./MenuTreeSelect";

interface Props extends FreeElementSchemaProps {}
const AddMenuBox = (props: Props) => {
  const { field } = props;
  const { value = [], onChange } = field;
  const { dispatch, fetchThunk, API_PATHS } = useGeneralHook();

  const { data = [] } = useSWR(
    API_PATHS.menu.getAll({ appId: process.env.REACT_APP_APP_ID }),
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    }
  );

  const getAllChild = useCallback((value): any => {
    if (value.children?.length > 0) {
      return value.children.reduce(
        (v, c) => {
          return [...v, ...getAllChild(c)];
        },
        [value]
      );
    }
    return [value];
  }, []);

  const getAllParent = useCallback(
    (value): any => {
      let tmp = [value];
      const help = (val) => {
        const parent = data?.find((v) => v.id === val.parentId);
        if (parent) {
          tmp.push(parent);
        } else {
          return;
        }
        help(parent);
      };
      help(value);

      return tmp;
    },
    [data]
  );

  const mappedData = useMemo(() => {
    const getChild = (parentId) => {
      return data
        ?.filter((v) => v.parentId === parentId)
        .map((v) => {
          return { ...v, children: getChild(v.id) };
        })
        ?.sort((a, b) =>
          a.sortOrder ? (a.sortOrder || 0) - (b.sortOrder || 0) : 1
        );
    };
    return getChild(null);
  }, [data]);

  return (
    <>
      <Typography variant="subtitle1">
        <FormattedMessage id="role.addMenu" />
      </Typography>
      <Paper variant="outlined" sx={{ maxHeight: 534, overflow: "hidden" }}>
        <Box display={"flex"} alignItems="center">
          <Checkbox
            checked={isEqual(
              value.map((v) => v.id).sort(),
              data.map((v) => v.id).sort()
            )}
            onChange={(e) => {
              if (e.target.checked) {
                onChange(data);
              } else {
                onChange([]);
              }
            }}
          />
          <Typography>
            <FormattedMessage id="all" />
          </Typography>
        </Box>
        <Divider />
        <MenuTreeSelect
          data={mappedData}
          selected={value}
          onSelect={(val, check) => {
            if (check) {
              onChange(
                uniqBy(
                  [...value, ...getAllChild(val), ...getAllParent(val)],
                  "id"
                )
              );
            } else {
              onChange(
                value.filter((v, index) => {
                  return (
                    getAllChild(val).findIndex((o) => o.id === v.id) === -1
                  );
                })
              );
            }
          }}
        />
      </Paper>
    </>
  );
};
export default AddMenuBox;
