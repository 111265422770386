// Material UI
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import { Box, IconButton } from "@mui/material";
import React, { useEffect, useState } from "react";
import RGL, { WidthProvider } from "react-grid-layout";
import BarChartBox from "../component/BarChartBox";
import CompareBox from "../component/CompareBox";
import ConstructionBox from "../component/ConstructionBox";
import ConstructionStatusBox from "../component/ConstructionStatusBox";
import GroupChart from "../component/GroupChart";
import "./style.css";

function getFromLS(key) {
  let ls = {};
  if (window.localStorage) {
    try {
      ls = JSON.parse(window.localStorage.getItem("rgl-8") || "") || {};
    } catch (e) {
      /*Ignore*/
    }
  }
  return ls[key];
}

function saveToLS(key, value) {
  if (window.localStorage) {
    window.localStorage.setItem(
      "rgl-8",
      JSON.stringify({
        [key]: value,
      })
    );
  }
}

const ReactGridLayout = WidthProvider(RGL);
const originalLayouts = getFromLS("layouts") || [
  {
    w: 9,
    h: 13,
    x: 0,
    y: 0,
    i: "0",
  },
  {
    w: 3,
    h: 13,
    x: 9,
    y: 0,
    i: "1",
  },
  {
    w: 6,
    h: 27,
    x: 0,
    y: 13,
    i: "2",
  },
  {
    w: 6,
    h: 27,
    x: 6,
    y: 13,
    i: "3",
  },
  {
    w: 12,
    h: 18,
    x: 0,
    y: 40,
    i: "4",
  },
];

const LIST_COMPONENT = {
  0: <ConstructionBox />,
  1: <ConstructionStatusBox />,
  2: <GroupChart />,
  3: <BarChartBox />,
  4: <CompareBox />,
};

const DashboardPage = () => {
  const [tick, setTick] = useState(false);
  const [editAble, setEdit] = useState(false);

  const [layout, setLayout] = React.useState<any>(
    JSON.parse(JSON.stringify(originalLayouts))
  );
  console.log("layout", layout);

  useEffect(() => {
    setTimeout(() => {
      setTick((old) => !old);
    }, 500);
  }, []);

  return (
    <Box width="100%" key={`${tick}`}>
      <ReactGridLayout
        className="layout"
        items={6}
        layout={layout}
        onLayoutChange={(val) => {
          saveToLS("layouts", val);
          setLayout(val);
        }}
        rowHeight={8}
        isResizable={editAble}
        isDraggable={editAble}
        margin={[16, 16]}
        containerPadding={[0, 0]}
      >
        {layout?.map((l, i) => {
          return (
            <Box key={l.i} data-grid={l} position={"relative"}>
              {LIST_COMPONENT[i]}
              {editAble && (
                <Box
                  sx={{
                    position: "absolute",
                    top: 0,
                    right: 0,
                    bottom: 0,
                    left: 0,
                    zIndex: 2,
                    cursor: "grab",
                    "&:hover": {
                      bgcolor: "rgba(224,224,224,0.2)",
                    },
                    clipPath:
                      "polygon(0 0, 100% 0, 100% calc(100% - 25px), calc(100% - 25px) calc(100% - 25px), calc(100% - 25px) 100%, 0 100%)",
                  }}
                />
              )}
            </Box>
          );
        })}
      </ReactGridLayout>
      <IconButton
        sx={{
          position: "fixed",
          right: 24,
          bottom: 24,
          bgcolor: editAble ? "primary.main" : "action.disabled",
          "&:hover": {
            bgcolor: editAble ? "primary.dark" : "action.active",
          },
          color: "background.paper",
          zIndex: 999,
        }}
        onClick={() => {
          setEdit((old) => !old);
        }}
      >
        <AutoAwesomeMosaicIcon color="inherit" />
      </IconButton>
    </Box>
  );
};

export default DashboardPage;
