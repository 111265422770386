// Material UI
import { TabContext, TabList } from "@mui/lab";
import {
  Box,
  Divider,
  MenuItem,
  Paper,
  Select,
  Tab,
  Typography,
} from "@mui/material";
import { useMemo, useState } from "react";
import Chart from "react-apexcharts";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";

const OptionsFilter = [
  { value: 1, timeRange: 7, timeRangeType: "day" },
  { value: 2, timeRange: 1, timeRangeType: "month" },
  { value: 3, timeRange: 3, timeRangeType: "month" },
  { value: 4, timeRange: 1, timeRangeType: "year" },
  { value: 5, timeRange: 2, timeRangeType: "year" },
  { value: 6, timeRange: 3, timeRangeType: "year" },
];

interface Props {}
const BarChartBox = (props: Props) => {
  const { dispatch, API_PATHS, appState } = useGeneralHook();
  const [filterParams, setFilterParams] = useState<Number>(1);
  const [typeData, setTypeData] = useState<"obsdata" | "predata">("obsdata");

  const { data = [] } = useSWR(
    API_PATHS.dashboard.dataReceive({
      ...OptionsFilter.find((v) => v.value === filterParams),
      typeData,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  const series = useMemo(() => {
    return [
      {
        name: "main",
        data: data?.map((item) => {
          return item.ratio;
        }),
      },
    ];
  }, [data]);

  return (
    <Box height="100%" display={"flex"} flexDirection="column">
      <Typography variant="h6">
        <FormattedMessage id="dashboard.dataReceive" />
        <Typography variant="body2" component={"span"} color="textSecondary">
          <FormattedMessage id="dashboard.dataReceiveNote" />
        </Typography>
      </Typography>
      <TabContext value={typeData}>
        <Paper
          sx={{
            flex: 1,
            display: "flex",
            flexDirection: "column",
            marginTop: 1.5,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent={"space-between"}
            gap={1}
            px={2}
          >
            <TabList
              onChange={(e, newValue) => {
                setTypeData(newValue);
              }}
              variant="scrollable"
              indicatorColor="primary"
            >
              <Tab
                value={"obsdata"}
                style={{ minWidth: 100 }}
                disableTouchRipple
                label={<FormattedMessage id={"dashboard.obsdata"} />}
              />
              <Tab
                value={"predata"}
                style={{ minWidth: 100 }}
                disableTouchRipple
                label={<FormattedMessage id={"dashboard.predata"} />}
              />
            </TabList>
            <Select
              value={filterParams}
              onChange={(e) => {
                setFilterParams(Number(e.target.value));
              }}
              style={{ width: 120 }}
            >
              {OptionsFilter.map((value, index) => {
                return (
                  <MenuItem key={index} value={value.value}>
                    {value.timeRange}&nbsp;
                    <FormattedMessage id={value.timeRangeType} />
                  </MenuItem>
                );
              })}
            </Select>
          </Box>
          <Divider />
          <Box flex={1}>
            <Chart
              options={{
                theme: {
                  mode: appState.common.mode === "light" ? "light" : "dark",
                },
                plotOptions: {
                  bar: {
                    horizontal: false,
                    borderRadius: 4,
                    columnWidth: "45%",
                  },
                },
                chart: {
                  type: "bar",
                  events: {
                    dataPointSelection: (event, chartContext, config) => {
                      console.log(
                        config.w.config.labels[config.dataPointIndex],
                        config
                      );
                    },
                  },
                },
                colors: ["#9C27B0"],
                xaxis: {
                  categories: data?.map((v) => v.name),
                },
              }}
              series={series}
              type="bar"
              height={"95%"}
            />
          </Box>
        </Paper>
      </TabContext>
    </Box>
  );
};

export default BarChartBox;
