import {
  Box,
  Button,
  ButtonGroup,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  Popover,
  Typography,
} from "@mui/material";
import { useDropzone } from "react-dropzone";
import DialogCustomFullScreen from "../../../common/components/DialogCustomFullScreen";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";
import { SEPARATE_CODE } from "../constant";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FormattedMessage } from "react-intl";
import DrawerCustom from "../../../common/components/DrawerCustom";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import { useCallback, useMemo, useState } from "react";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import { KeyboardArrowDownRounded } from "@mui/icons-material";
const baseStyle = {
  flex: 1,
  display: "flex",
  flexDirection: "column" as any,
  alignItems: "center",
  justifyContent: "center",
  padding: 2,
  border: 2,
  borderRadius: 1,
  borderColor: "divider",
  borderStyle: "dashed",
  bgcolor: "grey.50",
  color: "text.secondary",
  outline: "none",
  transition: "border .24s ease-in-out",
  width: "100%",
  minHeight: 220,
  cursor: "pointer",
};

const focusedStyle = {
  borderColor: "primary.main",
};

const acceptStyle = {
  borderColor: "secondary.main",
};

const rejectStyle = {
  borderColor: "error.main",
};
const errorStyle = {
  borderColor: "error.main",
};

interface Props {
  onSubmit: (file: File[], formVal: some) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const UploadDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, onSubmit, formData } = props;
  const { setLoading, dispatch, fetchThunk, API_PATHS, enqueueSnackbar } =
    useGeneralHook();
  const [error, setError] = useState(false);

  const getSampleFile = useCallback(async () => {
    try {
      const json = await dispatch(
        fetchThunk(API_PATHS.periodic.sampleFile, "get", undefined, "file")
      );
      setLoading(false);
      if (json.status !== 200) {
        return;
      }
      const newBlob = new Blob([json.blob as Blob]);
      const blobUrl = window.URL.createObjectURL(newBlob);
      const link = document.createElement("a");
      link.href = blobUrl;
      link.setAttribute("download", "FORM.xlsx");
      document.body.appendChild(link);
      link.click();
      link.parentNode?.removeChild(link);
      window.URL.revokeObjectURL(json.blob as any);
    } catch (e: any) {
      enqueueSnackbar({
        message: <FormattedMessage id="downloadFail" />,
        variant: "success",
      });
    }
  }, [API_PATHS.periodic, dispatch, enqueueSnackbar, fetchThunk, setLoading]);

  const onDrop = useCallback(async (files: any) => {
    setError(false);
  }, []);

  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ maxSize: 50000000, onDrop: onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
      ...(error ? errorStyle : {}),
    }),
    [isFocused, isDragAccept, isDragReject, error]
  );

  const onSubmitForm = async () => {
    if (!acceptedFiles.length) {
      setError(true);
      return;
    } else {
      formData && onSubmit(acceptedFiles, formData);
    }
  };

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={"periodicMonitoring.importData"}
    >
      <Box padding={2}>
        <Box display="flex" gap={1} marginBottom={3}>
          <Box
            bgcolor="primary.main"
            height={24}
            width={24}
            borderRadius={4}
            color="white"
          >
            <KeyboardArrowRightIcon />
          </Box>
          <Box display={"flex"} flexDirection="column">
            <Typography variant="body2">
              <FormattedMessage id="periodicMonitoring.sampleFile" />
            </Typography>
            <Typography variant="caption" color="textSecondary">
              <FormattedMessage id="periodicMonitoring.noteFile" />
            </Typography>
            <Button
              startIcon={<DownloadIcon />}
              variant="outlined"
              style={{ flex: 1, width: "fit-content" }}
              onClick={getSampleFile}
            >
              <FormattedMessage id="periodicMonitoring.sampleFileName" />
            </Button>
          </Box>
        </Box>
        <FormControl error={error} fullWidth>
          <InputLabel required={true} style={{ marginBottom: 10 }}>
            <FormattedMessage id="chooseFileImport" />
          </InputLabel>
          <Box {...getRootProps()} sx={style}>
            <input {...getInputProps()} />
            {acceptedFiles?.length > 0 ? (
              <Box
                display="flex"
                flexDirection="column"
                gap={1}
                marginBottom={2}
              >
                {acceptedFiles.map((v) => {
                  return v.name;
                })}
              </Box>
            ) : (
              <>
                <CloudUploadIcon style={{ fontSize: 90 }} color="primary" />
                <p>
                  <FormattedMessage id="dropzone_placeholder" />
                </p>
              </>
            )}
            <Button>
              <FormattedMessage id="dropzone.choseFile" />
            </Button>
          </Box>
        </FormControl>
        <LoadingButton
          style={{ marginTop: 24, width: 120 }}
          onClick={onSubmitForm}
        >
          <FormattedMessage id="save" />
        </LoadingButton>
      </Box>
    </DrawerCustom>
  );
};
export default UploadDialog;
