import { Box } from "@mui/material";
import * as React from "react";
import DrawerCustom from "../../../common/components/DrawerCustom";
import { some } from "../../../common/constants";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  onSubmit: (formVal: some, setLoading: (loading: boolean) => void) => void;
  open: boolean;
  onClose: () => void;
  formData?: some;
}

const SchemaFormContent: React.FunctionComponent<Props> = (props) => {
  const { onSubmit, formData, onClose } = props;
  return (
    <Box padding={2}>
      <SchemaForm
        schema={{
          fields: ({
            formProps: { intl, dispatch, API_PATHS, fetchThunk },
          }) => {
            return {
              indicator: {
                type: "auto-complete",
                label: intl.formatMessage({ id: "indicator.name" }),
                placeholder: intl.formatMessage({
                  id: "select",
                }),
                loadOptions: async (name: string) => {
                  const json = await dispatch(
                    fetchThunk(
                      API_PATHS.indicator.index({
                        name,
                      })
                    )
                  );
                  return json.data?.items?.map((v) => ({
                    id: v.id,
                    name: v.name,
                    unit: v.unit,
                    code: v.code,
                  }));
                },
                getOptionLabel: (options) =>
                  `${options.name}-${options.code}(${options.unit})`,
                register: { required: true },
              },
              isAuto: {
                type: "checkbox",
                label: intl.formatMessage({
                  id: "constructionIndicator.isAuto",
                }),
                fromControlProps: { style: { margin: 0 } },
              },
              isPeriodic: {
                type: "checkbox",
                label: intl.formatMessage({
                  id: "constructionIndicator.isPeriodic",
                }),
                fromControlProps: { style: { margin: 0 } },
              },
            };
          },
        }}
        onSubmit={onSubmit}
        initialData={formData}
        onCancel={onClose}
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
      />
    </Box>
  );
};

const FormIndicatorDialog: React.FunctionComponent<Props> = (props) => {
  const { open, onClose, formData } = props;

  return (
    <DrawerCustom
      open={!!open}
      keepMounted={false}
      onClose={onClose}
      PaperProps={{ style: { width: 320 } }}
      title={formData?.id ? "update" : "create"}
    >
      <SchemaFormContent {...props} />
    </DrawerCustom>
  );
};
export default FormIndicatorDialog;
