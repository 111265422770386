/* eslint-disable react-hooks/exhaustive-deps */
// Material UI
import ArrowForwardRoundedIcon from "@mui/icons-material/ArrowForwardRounded";
import {
  Avatar,
  Box,
  Grid,
  IconButton,
  Paper,
  Typography,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import { FormattedMessage } from "react-intl";
import useSWR from "swr";
import LinkPM from "../../../../common/components/LinkPM";
import { some } from "../../../../common/constants";
import useGeneralHook from "../../../../common/hook/useGeneralHook";
import { fetchThunk } from "../../../../common/reducer/thunk";
import { stringifyUrl } from "../../../../common/utils";
import { ROUTES } from "../../../../layout/constants";
interface Props {}

const ConstructionBox = (props: Props) => {
  const { dispatch, API_PATHS } = useGeneralHook();
  const { data } = useSWR(
    API_PATHS.dashboard.construction,
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false }
  );

  return (
    <Box height="100%" paddingBottom={4}>
      <Typography variant="h6" marginBottom={2}>
        <FormattedMessage id="dashboard.summaryConStruction" />{" "}
        <Typography variant="body2" component="span">
          <FormattedMessage
            id="dashboard.summary"
            values={{
              num: data?.reduce((v, c) => v + c?.total, 0),
            }}
          />
        </Typography>
      </Typography>
      <Grid container spacing={2} height="100%">
        {data?.map((item: some, index: number) => {
          return (
            <Grid key={index} item xs={4}>
              <Paper
                variant="outlined"
                sx={{
                  padding: 1.5,
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: 1,
                }}
              >
                <Avatar
                  src={API_PATHS.upload.getFile(item.icon)}
                  style={{ background: item.color }}
                >
                  &nbsp;
                </Avatar>
                <Box flex={1}>
                  <Typography variant="subtitle2">{item.name}</Typography>
                  <Typography variant="caption" color="textSecondary">
                    <FormattedMessage
                      id="dashboard.constructionAmount"
                      values={{
                        num: item.total,
                      }}
                    />
                  </Typography>
                </Box>
                <Box>
                  <LinkPM
                    to={{
                      pathname: ROUTES.monitorOnline.gen(item.nreTypeId),
                      search: stringifyUrl({
                        constructionTypeId: { id: item.id, name: item.name },
                      }),
                    }}
                  >
                    <IconButton>
                      <ArrowForwardRoundedIcon color="primary" />
                    </IconButton>
                  </LinkPM>
                </Box>
              </Paper>
            </Grid>
          );
        })}
      </Grid>
    </Box>
  );
};

export default ConstructionBox;
