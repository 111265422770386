import KeyboardArrowDownRoundedIcon from "@mui/icons-material/KeyboardArrowDownRounded";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import RoomOutlined from "@mui/icons-material/RoomOutlined";
import VideoCameraBackOutlined from "@mui/icons-material/VideoCameraBackOutlined";
import {
  Box,
  Collapse,
  darken,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { get } from "lodash";
import moment from "moment";
import * as React from "react";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { useParams } from "react-router";
import useSWR from "swr";
import { PopoverState } from "../../../common/components/elements";
import LinkPM from "../../../common/components/LinkPM";
import TableCustom from "../../../common/components/TableCustom";
import { Columns } from "../../../common/components/TableCustom/utils";
import { DATE_TIME_FORMAT, some } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import usePaginationHook from "../../../common/hook/usePaginationHook";
import { capitalizeFirstLetter, scrollTo } from "../../../common/utils";
import { ROUTES } from "../../../layout/constants";
import { LIST_STATUS_STATION } from "../../dashboard/constants";
import IndicatorBox from "./IndicatorBox";
interface Props {
  info: some;
  filterParams: some;
}
const Table = (props: Props) => {
  const { info, filterParams } = props;
  const { nreTypeId } = useParams<{ nreTypeId: string }>();
  const [open, setOpen] = React.useState(false);
  const [filter, setFilter] = React.useState<some>({ columns: [] });
  const { pagination, onPageChange, onRowsPerPageChange } = usePaginationHook({
    disableLink: true,
  });
  const { dispatch, API_PATHS, fetchThunk, theme } = useGeneralHook();

  const { data, isValidating } = useSWR(
    API_PATHS.monitoring.index({
      ...filterParams,
      ...pagination,
      constructionTypeId: info?.id,
      nreTypeId,
    }),
    async (url: string) => {
      const json = await dispatch(fetchThunk(url));
      return json?.data;
    },
    { revalidateOnFocus: false, refreshInterval: 60000 }
  );

  const mappedData = React.useMemo(() => {
    return data?.items;
  }, [data?.items]);

  const columns = [
    {
      title: "construction.name",
      render: (record: some) => {
        const label = LIST_STATUS_STATION.find((v) =>
          v.value?.includes(record.status)
        )?.label;
        const color = LIST_STATUS_STATION.find((v) =>
          v.value?.includes(record.status)
        )?.color;
        return (
          <Box
            display="flex"
            alignItems="center"
            gap={1}
            style={{ minHeight: 42 }}
          >
            <Tooltip title={label ? <FormattedMessage id={label} /> : ""}>
              <Box
                sx={{
                  width: 8,
                  height: 8,
                  borderRadius: "50%",
                  bgcolor: color,
                  flexShrink: 0,
                }}
              />
            </Tooltip>
            <Typography variant="caption">{record.name}</Typography>
          </Box>
        );
      },
    },
    {
      title: "time",
      align: "center",
      width: 120,
      fixed: "left",
      render: (record: some) => {
        return (
          <>
            {record.data?.time &&
              moment(record.data.time).format(DATE_TIME_FORMAT)}
          </>
        );
      },
    },
    ...(filter?.columns || [])?.map((column) => {
      if (info.nreType?.code === "NDD") {
        return {
          title: (
            <Box>
              <Typography
                variant="caption"
                fontWeight="bold"
                style={{ wordBreak: "break-word" }}
                component="div"
              >
                {capitalizeFirstLetter(column.name)}
              </Typography>
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ wordBreak: "break-word" }}
              >
                {column.unit}
              </Typography>
            </Box>
          ),
          headerProps: { style: { paddingRight: 0, paddingLeft: 0 } },
          align: "center",
          children: column.indicators?.map((indicator) => {
            return {
              title: indicator.name,
              align: "center",
              render: (record: some) => {
                const value = get(record.data, `${indicator.dataColumnCode}_v`);
                const status = get(
                  record.data,
                  `${indicator.dataColumnCode}_s`
                );
                const label = LIST_STATUS_STATION.find(
                  (v) => v.value === status
                )?.label;
                const color = LIST_STATUS_STATION.find(
                  (v) => v.value === status
                )?.color;
                return (
                  <Box>
                    {typeof value === "number" ? (
                      <Typography
                        variant="caption"
                        color={
                          record?.status === 99
                            ? "textSecondary"
                            : "success.main"
                        }
                        style={{
                          wordBreak: "break-word",
                        }}
                      >
                        {typeof value === "number" && (
                          <FormattedNumber
                            value={value}
                            maximumFractionDigits={2}
                          />
                        )}
                      </Typography>
                    ) : (
                      "-"
                    )}
                    {color && (
                      <Tooltip
                        title={label ? <FormattedMessage id={label} /> : ""}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            bottom: 4,
                            left: "calc(50% - 4px)",
                            width: 8,
                            height: 8,
                            borderRadius: "50%",
                            bgcolor: color,
                            flexShrink: 0,
                            cursor: "pointer",
                          }}
                        />
                      </Tooltip>
                    )}
                  </Box>
                );
              },
            };
          }),
        };
      }
      return {
        title: (
          <Box>
            <Typography
              variant="caption"
              fontWeight="bold"
              style={{ wordBreak: "break-word" }}
              component="div"
            >
              {capitalizeFirstLetter(column.name)}
            </Typography>
            <Typography
              variant="caption"
              color="textSecondary"
              style={{ wordBreak: "break-word" }}
            >
              {column.unit}
            </Typography>
          </Box>
        ),
        headerProps: { style: { paddingRight: 0, paddingLeft: 0 } },
        minWidth: 70,
        align: "center",
        props: (record: some) => {
          const value = get(record.data, `${column.dataColumnCode}.status`);
          return {
            style: {
              backgroundColor:
                record?.status === 99 && value
                  ? darken(theme.palette.background.default, 0.05)
                  : undefined,
              padding: 0,
              position: "relative",
            },
          };
        },
        render: (record: some) => {
          const value = get(record.data, `${column.dataColumnCode}_v`);
          const status = get(record.data, `${column.dataColumnCode}_s`);
          const label = LIST_STATUS_STATION.find(
            (v) => v.value === status
          )?.label;
          const color = LIST_STATUS_STATION.find(
            (v) => v.value === status
          )?.color;
          return (
            <Box>
              {typeof value === "number" ? (
                <Typography
                  variant="caption"
                  color={
                    record?.status === 99 ? "textSecondary" : "success.main"
                  }
                  style={{
                    wordBreak: "break-word",
                  }}
                >
                  {typeof value === "number" && (
                    <FormattedNumber value={value} maximumFractionDigits={2} />
                  )}
                </Typography>
              ) : (
                "-"
              )}
              {color && (
                <Tooltip title={label ? <FormattedMessage id={label} /> : ""}>
                  <Box
                    sx={{
                      position: "absolute",
                      bottom: 4,
                      left: "calc(50% - 4px)",
                      width: 8,
                      height: 8,
                      borderRadius: "50%",
                      bgcolor: color,
                      flexShrink: 0,
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              )}
            </Box>
          );
        },
      };
    }),
    {
      title: "action",
      align: "center",
      fixed: "right",
      width: 60,
      render: (record: some, index: number) => (
        <>
          <PopoverState>
            {({ anchorEl, handleClick, handleClose, open }) => {
              return (
                <>
                  <IconButton onClick={handleClick} style={{ padding: 4 }}>
                    <MoreHorizIcon color="inherit" />
                  </IconButton>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem onClick={handleClose}>
                      <ListItemIcon>
                        <VideoCameraBackOutlined />
                      </ListItemIcon>
                      <ListItemText>
                        <Typography variant="body2">
                          <FormattedMessage id="monitor.camera" />
                        </Typography>
                      </ListItemText>
                    </MenuItem>
                    <LinkPM
                      to={{
                        pathname: `/${ROUTES.dashboard.index}/${ROUTES.dashboard.mapConstruction}`,
                        search: `?constructionId=${record.id}`,
                      }}
                    >
                      <MenuItem onClick={handleClose}>
                        <ListItemIcon>
                          <RoomOutlined />
                        </ListItemIcon>
                        <ListItemText>
                          <Typography variant="body2">
                            <FormattedMessage id="monitor.stationLocation" />
                          </Typography>
                        </ListItemText>
                      </MenuItem>
                    </LinkPM>
                  </Menu>
                </>
              );
            }}
          </PopoverState>
        </>
      ),
    },
  ] as Columns[];

  React.useEffect(() => {
    if (mappedData?.length > 0) {
      setOpen(true);
    }
  }, [mappedData]);

  return (
    <>
      <Paper style={{ padding: 8, marginBottom: 24 }}>
        <div id={info.id} />
        <Box display="flex" alignItems="center">
          <Typography variant="subtitle1" style={{ flex: 1 }}>
            {info.name}
          </Typography>
          <IndicatorBox
            params={{ nreTypeId, constructionTypeId: info.id }}
            filter={filter}
            setFilter={setFilter}
          />
          <IconButton
            size="small"
            onClick={() => {
              setOpen(!open);
            }}
          >
            <KeyboardArrowDownRoundedIcon
              color="primary"
              style={{
                transform: `rotate(${open ? 0 : 180}deg)`,
                transition: "0.3s all",
              }}
            />
          </IconButton>
        </Box>
        <Collapse in={open} unmountOnExit>
          <TableCustom
            doubleScroll
            containerProps={{
              sx: {
                "& td": { padding: "0px 4px", position: "relative" },
                marginTop: 1,
              },
            }}
            dataSource={mappedData}
            loading={isValidating}
            columns={columns}
            paginationProps={{
              count: data?.total,
              page: pagination.page,
              rowsPerPage: pagination.pageSize,
              onPageChange: (a, b) => {
                scrollTo(info.id, 80);
                onPageChange(a, b);
              },
              onRowsPerPageChange: onRowsPerPageChange,
            }}
          />
        </Collapse>
      </Paper>
    </>
  );
};
export default Table;
