import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IconButton, InputAdornment } from "@mui/material";
import * as React from "react";
import useSWR from "swr";
import { some, SUCCESS_CODE } from "../../../common/constants";
import useGeneralHook from "../../../common/hook/useGeneralHook";
import SchemaForm from "../../../common/SchemaForm";

interface Props {
  constructionData?: some;
}
const MQTTTab: React.FunctionComponent<Props> = (props) => {
  const { constructionData } = props;

  const { dispatch, enqueueSnackbar, API_PATHS, fetchThunk } = useGeneralHook();

  const { data, isValidating, revalidate } = useSWR(
    constructionData?.id ? API_PATHS.mqtt.index(constructionData?.id) : null,
    async (url) => {
      const json = await dispatch(fetchThunk(url));
      return json.data;
    },
    { revalidateOnFocus: false }
  );

  const onSubmit = React.useCallback(
    async (value: some, setLoading: (loading: boolean) => void) => {
      setLoading(true);
      const json = await dispatch(
        fetchThunk(
          API_PATHS.mqtt.index(constructionData?.id),
          value.id ? "put" : "post",
          value
        )
      );
      setLoading(false);
      if (json.code === SUCCESS_CODE) {
        revalidate();
      }
      enqueueSnackbar({
        message: json.msg,
        requestId: json.requestId,
        variant: json.code,
      });
    },
    [
      dispatch,
      fetchThunk,
      API_PATHS.mqtt,
      constructionData?.id,
      enqueueSnackbar,
      revalidate,
    ]
  );

  return (
    <>
      <SchemaForm
        loading={isValidating}
        onSubmit={onSubmit}
        constructionData={data}
        onReset
        formProps={{
          autoComplete: "nope",
          autoCorrect: "off",
        }}
        schema={{
          fields: ({ formProps: { intl, state, setState } }) => {
            return {
              url: {
                type: "text-field",
                label: intl.formatMessage({ id: "mqtt.url" }),
                placeholder: intl.formatMessage({ id: "mqtt.urlEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              topic: {
                type: "text-field",
                label: intl.formatMessage({ id: "mqtt.topic" }),
                placeholder: intl.formatMessage({ id: "mqtt.topicEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              user: {
                type: "text-field",
                label: intl.formatMessage({ id: "mqtt.userName" }),
                placeholder: intl.formatMessage({ id: "mqtt.userNameEnter" }),
                propsWrapper: { xs: 6 },
                register: {
                  required: true,
                },
              },
              password: {
                type: "text-field",
                inputType: state?.showPassword ? "text" : "password",
                label: intl.formatMessage({ id: "mqtt.password" }),
                placeholder: intl.formatMessage({ id: "mqtt.passwordEnter" }),
                propsWrapper: { xs: 6 },
                InputProps: {
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        tabIndex={-1}
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setState({ showPassword: !state.showPassword })
                        }
                      >
                        {!state.showPassword ? (
                          <VisibilityOff fontSize="small" />
                        ) : (
                          <Visibility fontSize="small" />
                        )}
                      </IconButton>
                    </InputAdornment>
                  ),
                  style: { borderRadius: 4, overflow: "hidden" },
                },
                register: {
                  required: true,
                },
              },

              qos: {
                type: "select",
                label: intl.formatMessage({ id: "mqtt.qos" }),
                placeholder: intl.formatMessage({ id: "select" }),
                options: [
                  {
                    id: 0,
                    name: 0,
                  },
                  {
                    id: 1,
                    name: 1,
                  },
                  {
                    id: 2,
                    name: 2,
                  },
                ],
                propsWrapper: { xs: 6 },
              },
              blank: {
                unregister: true,
                propsWrapper: { xs: 6 },
              },
              cleanSession: {
                type: "checkbox",
                label: intl.formatMessage({ id: "mqtt.cleanSession" }),
                propsWrapper: { xs: 3 },
                fromControlProps: { style: { margin: 0 } },
                noHelperText: true,
              },
              quietMode: {
                type: "checkbox",
                label: intl.formatMessage({ id: "mqtt.quietMode" }),
                propsWrapper: { xs: 3 },
                fromControlProps: { style: { margin: 0 } },
                noHelperText: true,
              },
            };
          },
        }}
      />
    </>
  );
};

export default MQTTTab;
