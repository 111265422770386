import { Box } from "@mui/material";
import * as React from "react";
import { Outlet } from "react-router";
import LoadingIcon from "../common/components/LoadingIcon";
import useGeneralHook from "../common/hook/useGeneralHook";
import { loadNRETypeData } from "../modules/nreType/nreTypeReducer";
import DefaultAside from "./DefaultAside";
import DefaultBreadcrumb from "./DefaultBreadcrumbs";
import Header from "./Header";
import { IRouteObject } from "./utils";

interface Props {
  listRoutes: IRouteObject[];
}

const DefaultLayout: React.FunctionComponent<Props> = (props: Props) => {
  const { listRoutes } = props;
  const { dispatch } = useGeneralHook();

  React.useEffect(() => {
    dispatch(loadNRETypeData());
  }, [dispatch]);

  return (
    <Box
      display="flex"
      minHeight="100vh"
      style={{ height: "100vh", overflow: "hidden", width: "100%" }}
      bgcolor={"background.paper"}
    >
      <DefaultAside listRouter={listRoutes} />
      <Box
        paddingBottom={10}
        minHeight={"calc(100% - 40px)"}
        flexGrow={1}
        width="70vw"
        flex={1}
        position={"relative"}
        display="flex"
        flexDirection="column"
        paddingTop={7}
        paddingX={2}
        overflow="auto"
      >
        <Header openAside={true} />
        <DefaultBreadcrumb listRouter={listRoutes} />
        <React.Suspense fallback={<LoadingIcon />}>
          <Outlet />
        </React.Suspense>
      </Box>
    </Box>
  );
};

export default DefaultLayout;
